import React from "react";
import { useContext } from "react";
import { Context } from "../contexts/Store";
import Toolbar from "./Toolbar";
import URLBox from "./URLBox";
import PayloadForm from "./PayloadForm";
import ResponseViewer from "./ResponseViewer";
import Overview from "./Overview";
import styles from "./playground.module.css";
import "bootstrap-icons/font/bootstrap-icons.css"; // Import Bootstrap icons
import SaveMapping from "../../ShowDataTable/SaveMapping/SaveMapping";
import { useState } from "react";
import api from "../../Api";
import UploadSuccess from "../../UploadSuccess/UploadSuccess";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const Playground = () => {
  const { state } = useContext(Context);
  const location = useLocation();
  const [showSavePopUp, setShowSavePopUp] = useState(false);
  const [name, setName] = useState(null);
  const [errMessage, setErrMessage] = useState(null);
  const [showSaved, setShowSaved] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const userID = queryParams.get("userID");

  const handleSaveAPI = async () => {
    console.log(state);
    try {
      // Check if any tab has a non-empty URL
      const hasValidUrl = state.tabs.some((tab) => tab.url.trim() !== "");

      if (!hasValidUrl) {
        setErrMessage(
          "Cannot save an API without any endpoints. Please add at least one URL.",
        );
        return;
      }

      const res = await api.post("/saveAPI", { name, state, userID });
      setName("");
      setErrMessage("");
      setShowSavePopUp(false);
      setShowSaved(true);
      console.log(res.data);
    } catch (err) {
      const errorMessage =
        err.response?.data?.error ||
        err.response?.data?.details ||
        err.message ||
        "An unexpected error occurred";

      setErrMessage(errorMessage);
      console.error("Save API Error:", err);
    }
  };

  useEffect(() => {
    if (showSaved) {
      const timer = setTimeout(() => {
        setShowSaved(false);
      }, 3000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [showSaved]);

  return (
    <main className={styles.wrapper}>
      <Toolbar />
      {state.overviewTab === "shown" ? (
        <Overview />
      ) : (
        <div
          className={
            state.infoPanelOpened ? styles.main : styles.main_collapsed
          }
        >
          <div className={styles.container}>
            <div className={styles.panelheader}>
              <div className={styles.title_area}>
                <button className={styles.http_req}></button>
                <div className={styles.title_text}>
                  {state.tabs[state.activeTabId - 1]?.url || "no_name_request"}
                </div>
              </div>
              <div className={styles.options_area}>
                <div className={styles.save_options}>
                  <button type="button" onClick={() => setShowSavePopUp(true)}>
                    <i className="bi bi-floppy"></i>
                    <span className="save_text" style={{ paddingLeft: "5px" }}>
                      Save
                    </span>
                  </button>
                </div>
                {showSavePopUp ? (
                  <SaveMapping
                    name={name}
                    setName={setName}
                    onCancel={() => {
                      setName("");
                      setShowSavePopUp(false);
                      setErrMessage("");
                    }}
                    title="Enter API name"
                    onSave={() => handleSaveAPI()}
                    errMessage={errMessage}
                  />
                ) : null}
                {showSaved ? (
                  <UploadSuccess message="Credentials Saved Successfully" />
                ) : null}
                {/* <div className={styles.view_options}>
                  <button className={styles.chevron_btn}>
                    <i
                      className="bi bi-chevron-down"
                      style={{ color: "black", fontSize: "small" }}
                    ></i>
                  </button>
                  <button
                    className={styles.share_btn}
                    style={{
                      border: "2px solid var(--border-color)",
                      borderRadius: "5px",
                      marginRight: "5px",
                    }}
                  >
                    Share
                  </button>
                </div> */}
              </div>
            </div>
            <URLBox />
            <div
              className={
                state.splitView === "V"
                  ? styles.panel_vertical
                  : styles.panel_horizontal
              }
            >
              <PayloadForm />
              <ResponseViewer />
            </div>
          </div>
          {/* <div
            className={
              state.infoPanelOpened
                ? styles.panel_opened
                : styles.panel_collapsed
            }
          >
            <RightPanel />
          </div> */}
        </div>
      )}
    </main>
  );
};

export default Playground;
