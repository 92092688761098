import React from 'react';
import axios from 'axios';

const api = axios.create({
  // baseURL: "http://3.90.243.219:5001/api",
  baseURL: `/api`,
  timeout: 30000, // Add timeout
});

export default api;
