/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import styles from "./TemplateView.module.css";
import api from "../../Api";
import { useHistory, useLocation } from "react-router-dom";
import * as XLSX from "xlsx";
import TemplateUploadForm from "../TemplateUploadForm/TemplateUploadForm";
import WarningModal from "../../WarningModal/WarningModal";
import Loader from "../../Loader/Loader";

const TemplateView = ({ categories }) => {
  const isAdmin = localStorage.getItem("UserType") == 1;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const manageTemplateID = queryParams.get("manageTemplateID");
  const userID = queryParams.get("userID");
  const [templateData, setTemplateData] = useState(null);
  const [templateFields, setTemplateFields] = useState(null);
  const [showInfoForm, setShowInfoForm] = useState(false);
  const [showUploadForm, setShowUploadForm] = useState(false);
  const [errMessage, setErrMessage] = useState(null);
  const [overwrite, setOverwrite] = useState(null);
  const [templateInfo, setTemplateInfo] = useState(null);
  const [showWarning, setShowWarning] = useState(false);
  const [showBackWarning, setShowBackWarning] = useState(false); // New state for back button warning
  const [fields, setFields] = useState(null);
  const [clickType, setClickType] = useState(null);
  const navigate = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  // Modified back handler
  const handleBack = () => {
    // Check if the user is currently editing or duplicating
    if (isEditing || isDuplicating) {
      const fields = templateData?.columns?.map((field) => ({
        name: field?.column_name,
        mandatory: field?.is_mandatory,
      }));

      if (JSON.stringify(fields) === JSON.stringify(templateFields))
        navigate.goBack();
      setClickType(1);
      setShowBackWarning(true); // Show warning modal
    } else {
      navigate.goBack(); // Navigate back directly if not in edit/duplicate mode
    }
  };

  // Function to confirm going back without saving changes
  const confirmGoBack = () => {
    setShowBackWarning(false);
    if (clickType === 1) {
      setClickType(null);
      navigate.goBack();
    } else {
      setClickType(null);
      // Reset templateFields to match original data
      setTemplateFields(
        templateData?.columns?.map((field) => ({
          name: field?.column_name,
          mandatory: field?.is_mandatory,
        }))
      );
      setIsEditing(!isEditing);
    }
  };

  // Function to cancel going back
  const cancelGoBack = () => {
    setShowBackWarning(false);
  };

  const handleFetchFields = async () => {
    setIsLoading(true);
    if (!fields) {
      await api.get("/getAllFields").then((res) => {
        const data = res.data;
        setFields(data);
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (manageTemplateID) {
      api
        .get(`/getTemplateFields?templateID=${manageTemplateID}`)
        .then((res) => {
          const data = res.data;
          setTemplateData(data);
          setTemplateFields(
            data?.columns?.map((field) => ({
              name: field?.column_name,
              mandatory: field?.is_mandatory,
            })),
          );
        })
        .catch((err) => {
          alert(err.message);
        });
      handleFetchFields();
    }
  }, [manageTemplateID]);

  // State management
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isDuplicating, setIsDuplicating] = useState(false);
  const [selectedFields, setSelectedFields] = useState([]);
  const templateName = templateData?.template_name;

  // Download functionality
  const handleDownload = () => {
    // Extract the template name and columns
    const templateName = templateData?.template_name;
    const columns = templateData?.columns;

    // Create data for the Excel file
    const headerRow = columns?.map((field) => field?.column_name); // First row: column names
    const mandatoryRow = columns?.map((field) =>
      field?.is_mandatory ? "mandatory" : "optional",
    ); // Second row: 0 or 1 based on is_mandatory

    // Create a worksheet and add the rows
    const worksheet = XLSX.utils.aoa_to_sheet([headerRow, mandatoryRow]);

    // Create a workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Write the workbook and trigger download
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });

    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${templateName.replace(/\s+/g, "_")}.xlsx`;
    console.log("Download File Name:", link.download);
    link.click();

    // Cleanup
    URL.revokeObjectURL(url);
  };

  // Edit mode handlers
  const handleEditModeToggle = () => {
    if (isEditing) {
      const fields = templateData?.columns?.map((field) => ({
        name: field?.column_name,
        mandatory: field?.is_mandatory,
      }));

      if (JSON.stringify(fields) !== JSON.stringify(templateFields)) {
        setClickType(2);
        setShowBackWarning(true); // Show warning modal
        return;
      }

      setTemplateFields(
        templateData?.columns?.map((field) => ({
          name: field?.column_name,
          mandatory: field?.is_mandatory,
        })),
      );
    }
    setIsEditing(!isEditing);
  };

  const handleFieldMandatoryToggle = (index) => {
    const updatedFields = [...templateFields];
    updatedFields[index].mandatory = !updatedFields[index].mandatory;
    setTemplateFields(updatedFields);
  };

  const handleAddField = () => {
    // Get currently selected field names
    const selectedFieldNames = templateFields
      .map((field) => field.name)
      .filter((name) => name); // Filter out empty names

    // Filter out fields that are already selected
    const availableFields = fields.filter(
      (field) => !selectedFieldNames.includes(field.name),
    );

    // Only add new field if there are available fields to select from
    if (availableFields.length > 0) {
      setTemplateFields([
        ...templateFields,
        { name: "", mandatory: false, isNew: true },
      ]);
    }
  };

  // Add new function to get available fields
  const getAvailableFields = () => {
    if (!fields) return [];

    // Get currently selected field names
    const selectedFieldNames = templateFields
      .map((field) => field.name)
      .filter((name) => name); // Filter out empty names

    // Return fields that haven't been selected yet
    return fields.filter((field) => !selectedFieldNames.includes(field.name));
  };

  const handleFieldChange = (index, selectedFieldName) => {
    const selectedField = fields.find(
      (field) => field.name === selectedFieldName,
    );
    const updatedFields = [...templateFields];
    updatedFields[index] = {
      name: selectedField.name,
      mandatory: false,
      column_id: selectedField.column_id,
    };
    setTemplateFields(updatedFields);
  };

  const handleDeleteField = (index) => {
    setTemplateFields(templateFields.filter((_, i) => i !== index));
  };

  // Function to check if there are changes and all fields have valid names
  const canSave = () => {
    // Check for empty field names
    const hasEmptyName = templateFields.some((field) => field.name === "");
    if (hasEmptyName) return false;

    if (!templateData || !templateFields) return false;

    // Compare with original fields to check for changes
    const originalFields = templateData.columns.map((field) => ({
      name: field.column_name,
      mandatory: field.is_mandatory,
    }));

    // Check if length is different (this counts as a change)
    if (originalFields.length !== templateFields.length) return true;

    // Compare each field
    for (let i = 0; i < templateFields.length; i++) {
      const originalField = originalFields[i];
      const currentField = templateFields[i];

      if (
        !originalField ||
        originalField.name !== currentField.name ||
        originalField.mandatory !== currentField.mandatory
      ) {
        return true;
      }
    }

    // No changes found
    return false;
  };

  const handleSaveEdit = async () => {
    const fields = templateData?.columns?.map((field) => ({
      name: field?.column_name,
      mandatory: field?.is_mandatory,
    }));
    // Check if any element's name is an empty string in templateFields
    const hasEmptyName = templateFields.some((field) => field.name === "");
    if (hasEmptyName) {
      console.log("Cannot proceed, some fields have empty names.");
      return; // Stop the function if there are empty names
    }
    if (JSON.stringify(fields) !== JSON.stringify(templateFields)) {
      try {
        const res = await api.put(
          `/updateTemplateFields?templateID=${manageTemplateID}`,
          {fields:templateFields,userId:userID,templateId:manageTemplateID},
        );
        const data = res.data;
        setTemplateData(data);
        setTemplateFields(
          data?.columns?.map((field) => ({
            name: field?.column_name,
            mandatory: field?.is_mandatory,
          })),
        );
        setIsEditing(false);
      } catch (err) {
        setErrMessage(
          err?.response?.data?.message || "An unexpected error occurred.",
        );
        setShowWarning(true);
        console.log(err);
      }
    }
  };

  // Duplicate mode handlers
  const handleDuplicateToggle = () => {
    setIsDuplicating(!isDuplicating);
    setSelectedFields([]);
  };

  const handleFieldSelection = (index) => {
    setSelectedFields((prevSelected) => {
      if (prevSelected.includes(index)) {
        return prevSelected.filter((i) => i !== index);
      }
      return [...prevSelected, index];
    });
  };

  const handleContinueDuplicate = () => {
    setShowInfoForm(true);
    setShowUploadForm(true);
  };

  // Delete handlers
  const handleDeleteClick = () => setShowConfirmDelete(true);
  const handleDeleteCancel = () => setShowConfirmDelete(false);
  const handleDeleteConfirm = async () => {
    try {
      const templateID = manageTemplateID;
      // Send delete request to the API
      const res = await api.delete(`/deleteTemplate`, {
        params: { templateID, userID },
      });

      // Check if the response was successful
      if (res.status === 200) {
        // Perform actions based on successful deletion
        navigate.push(`/data-management/templates?section=2&userID=${userID}`);
      }
    } catch (error) {
      // Handle errors
      setErrMessage(
        error?.response?.data?.message || "An unexpected error occurred.",
      );
      setShowWarning(true);
      console.error("Error deleting template:", error);
    } finally {
      // Close the delete confirmation modal
      setShowConfirmDelete(false);
    }
  };

  const handleDuplicate = async (templateInfo) => {
    setTemplateInfo(templateInfo);
    const fieldsToDuplicate = selectedFields.map(
      (index) => templateFields[index],
    );
    const payload = { templateInfo, fieldsToDuplicate };

    try {
      const res = await api.post(
        `/duplicateTemplate?userID=${userID}`,
        payload,
      );
      console.log(res.message);
      setErrMessage(null);
      setShowUploadForm(false);
      setOverwrite(null);
      navigate.goBack();
    } catch (err) {
      // Handle errors
      setErrMessage(err?.response?.data?.message);
      if (err?.response?.data?.showOverwrite) {
        setOverwrite(err.response.data);
        setShowUploadForm(false);
      }
      console.log(err?.response?.data);
    }
  };

  const onCancel = () => {
    setErrMessage(null);
    setOverwrite(null);
    setShowUploadForm(true);
  };

  const onSave = async () => {
    const fieldsToDuplicate = selectedFields.map(
      (index) => templateFields[index],
    );
    const payload = { templateInfo, fieldsToDuplicate };
    try {
      await api.delete(`/deleteTemplate`, {
        params: { templateID: overwrite.templateID, userID },
      });

      const res = await api.post(
        `/duplicateTemplate?userID=${userID}`,
        payload,
      );
      console.log(res.message);
      setErrMessage(null);
      setShowUploadForm(false);
      setOverwrite(null);
      navigate.goBack();
    } catch (err) {
      // Handle errors
      setShowWarning(true);
      setErrMessage(err?.response?.data?.message);
      if (err?.response?.data?.showOverwrite) {
        setOverwrite(err.response.data);
      }
      setShowUploadForm(false);
      console.log(err?.response?.data);
    }
  };

  const ErrorMessageComponent = () => (
    <div className={styles.formWrap}>
      <div className={styles.errorContainer}>
        <p className={styles.errorMessage}>{overwrite?.message}</p>
        <div className={styles.buttonGroup}>
          <button className={styles.cancelButton} onClick={onCancel}>
            Cancel
          </button>
          <button className={styles.saveButton} onClick={onSave}>
            <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.589844 10.58L5.16984 6L0.589844 1.41L1.99984 0L7.99984 6L1.99984 12L0.589844 10.58Z"
                fill="#F7F7F8"
              />
            </svg>
            Save
          </button>
        </div>
      </div>
    </div>
  );

  // Render helper functions
  const renderActionButtons = () => (
    <div className={styles.actions}>
      {!isEditing && !isDuplicating ? (
        <button
          className={styles.actionButton}
          aria-label="Download template"
          onClick={handleDownload}
        >
          <i className="bi bi-download"></i> Download
        </button>
      ) : null}

      {!isEditing ? (
        <button
          className={styles.actionButton}
          aria-label={isDuplicating ? "Cancel Duplicate" : "Duplicate template"}
          onClick={handleDuplicateToggle}
        >
          {isDuplicating ? (
            <>
              <i className="bi bi-x-circle"></i> Cancel Duplicate
            </>
          ) : (
            <>
              <i className="bi bi-files"></i> Duplicate
            </>
          )}
        </button>
      ) : null}

      {isEditing ? (
        <button
          className={styles.actionButton}
          aria-label="Add new field"
          onClick={handleAddField}
        >
          <i className="bi bi-plus"></i> Add
        </button>
      ) : null}
    </div>
  );

  const renderField = (field, index) => {
    if (field.isNew) {
      return (
        <div
          key={index}
          className={styles.fieldItem}
          style={{
            flexDirection: isEditing && !isDuplicating ? "column" : "row",
          }}
        >
          <select
            className={styles.fieldSelect}
            value={field.name}
            onChange={(e) => handleFieldChange(index, e.target.value)}
          >
            <option value="">Select a field</option>
            {getAvailableFields().map((availableField) => (
              <option
                key={availableField.column_id}
                value={availableField.name}
              >
                {availableField.name}
              </option>
            ))}
          </select>

          <div
            className={styles.iconContainer}
            style={{
              width: isEditing && !isDuplicating ? "100%" : "auto",
              justifyContent:
                isEditing && !isDuplicating ? "space-between" : null,
            }}
          >
            <i
              className={`bi ${
                field.mandatory ? "bi-toggle2-on" : "bi-toggle2-off"
              } ${styles.toggleIcon}`}
              style={{
                color: field.mandatory ? "#d6756e" : "#6c757d",
                fontSize: "1.5rem",
                width: "2rem",
                height: "2rem",
              }}
              onClick={() => handleFieldMandatoryToggle(index)}
              aria-label={`Toggle mandatory status`}
            />

            <button
              className={styles.deleteIcon}
              onClick={() => handleDeleteField(index)}
              aria-label="Delete field"
            >
              <i className="bi bi-trash" style={{ color: "blue" }}></i>
            </button>
          </div>
        </div>
      );
    }

    if(isLoading){
      return <Loader />;
    }
    return (
      <div
        key={index}
        className={styles.fieldItem}
        style={{
          flexDirection: isEditing && !isDuplicating ? "column" : "row",
        }}
      >
        <span className={styles.fieldName}>{field.name}</span>

        <div
          className={styles.iconContainer}
          style={{
            width: isEditing && !isDuplicating ? "100%" : "auto",
            justifyContent:
              isEditing && !isDuplicating ? "space-between" : null,
          }}
        >
          {isEditing && !isDuplicating ? (
            <i
              className={`bi ${
                field.mandatory ? "bi-toggle2-on" : "bi-toggle2-off"
              } ${styles.toggleIcon}`}
              style={{
                color: field.mandatory ? "#d6756e" : "#6c757d",
                fontSize: "1.5rem",
                width: "2rem",
                height: "2rem",
              }}
              onClick={() => handleFieldMandatoryToggle(index)}
              aria-label={`Toggle ${field.name} mandatory status`}
            />
          ) : field.mandatory ? (
            <span className={styles.mandatoryMark}>*</span>
          ) : null}

          {isEditing && !isDuplicating && (
            <button
              className={styles.deleteIcon}
              onClick={() => handleDeleteField(index)}
              aria-label={`Delete ${field.name}`}
            >
              <i className="bi bi-trash" style={{ color: "blue" }}></i>
            </button>
          )}

          {isDuplicating && (
            <input
              type="checkbox"
              checked={selectedFields.includes(index)}
              onChange={() => handleFieldSelection(index)}
              aria-label={`Select ${field.name} for duplication`}
            />
          )}
        </div>
      </div>
    );
  };

  const renderBottomActions = () => (
    <div className={styles.bottomActions}>
      {!isEditing && !isDuplicating && isAdmin ? (
        <button
          className={styles.deleteButton}
          aria-label="Delete template"
          onClick={handleDeleteClick}
        >
          <i className="bi bi-trash"></i> Delete
        </button>
      ) : null}

      {!isDuplicating && isAdmin ? (
        <button
          className={styles.editButton}
          aria-label={isEditing ? "Cancel Edit" : "Edit template"}
          onClick={handleEditModeToggle}
        >
          {isEditing ? (
            <>
              <i className="bi bi-x-circle"></i> Cancel Edit
            </>
          ) : (
            <>
              <div style={{ display: "flex", alignItems: "center" }}>
                <svg
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  width="30"
                  height="15"
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M12.9 6.858l4.242 4.243L7.242 21H3v-4.243l9.9-9.9zm1.414-1.414l2.121-2.122a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414l-2.122 2.121-4.242-4.242z"></path>
                </svg>{" "}
                Edit
              </div>
            </>
          )}
        </button>
      ) : null}

      {isEditing && (
        <button
          className={styles.saveButton}
          aria-label="Save template changes"
          onClick={handleSaveEdit}
          disabled={!canSave()}
          style={{
            opacity: canSave() ? 1 : 0.5,
            cursor: canSave() ? "pointer" : "not-allowed",
          }}
        >
          <svg
            width="8"
            height="12"
            viewBox="0 0 8 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.589844 10.58L5.16984 6L0.589844 1.41L1.99984 0L7.99984 6L1.99984 12L0.589844 10.58Z"
              fill="#F7F7F8"
            />
          </svg>
          Save
        </button>
      )}
    </div>
  );

  const renderDeleteModal = () => (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <h3>Are you sure you want to delete the template?</h3>
        <div className={styles.modalActions}>
          <button onClick={handleDeleteCancel} className={styles.cancelButton}>
            Cancel
          </button>
          <button onClick={handleDeleteConfirm} className={styles.saveButton}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.58984 16.58L13.1698 12L8.58984 7.41L9.99984 6L15.9998 12L9.99984 18L8.58984 16.58Z"
                fill="#F7F7F8"
              />
            </svg>
            Delete
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.container}>
      <button className={styles.backButton} onClick={handleBack}>
        <svg
          width="8"
          height="12"
          viewBox="0 0 8 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 1.42L3.42 6L8 10.59L6.59 12L0.59 6L6.59 1.23266e-07L8 1.42Z"
            fill="#1232C3"
          />
        </svg>
        Back
      </button>
      <div className={styles.templateInfo}>
        <h2 className={styles.templateName}>{templateName}</h2>
        {renderActionButtons()}
      </div>

      {showWarning ? (
        <WarningModal
          icon={
            <i
              className="bi bi-exclamation-triangle-fill"
              style={{ color: "#D6746F" }}
            ></i>
          }
          message={errMessage}
          okButton="OK"
          onSave={() => setShowWarning(false)}
        />
      ) : null}

      {showBackWarning ? (
        <WarningModal
          icon={
            <i
              className="bi bi-exclamation-triangle-fill"
              style={{ color: "#D6746F" }}
            ></i>
          }
          message="You have unsaved changes. Are you sure you want to proceed without saving?"
          okButton="Don't Save"
          cancelButton="Cancel"
          onCancel={cancelGoBack}
          onSave={confirmGoBack}
        />
      ) : null}

      {overwrite ? ErrorMessageComponent() : null}

      {showInfoForm ? (
        <TemplateUploadForm
          onSave={handleDuplicate}
          onCancel={() => {
            setShowInfoForm(false);
            setShowUploadForm(false);
            setErrMessage(null);
            setOverwrite(null);
          }}
          categories={categories}
          errMessage={errMessage}
          showUploadForm={showUploadForm}
        />
      ) : null}

      {isEditing && !isDuplicating && (
        <div className={styles.iconTextContainer}>
          <i
            className={`bi bi-toggle2-on ${styles.toggleIcon}`}
            style={{
              color: "#d6756e",
              fontSize: "1.5rem",
              marginLeft: "1rem",
              width: "1rem",
              height: "1rem",
            }}
          ></i>
          <span
            style={{ color: "#d6756e", fontSize: "1rem", marginLeft: "0.5rem" }}
          >
            Mandatory field
          </span>
        </div>
      )}

      <div className={styles.scrollableContainer}>
        <div className={styles.fieldContainer}>
          {templateFields?.map(renderField)}
        </div>
      </div>

      {!isEditing && !isDuplicating && (
        <span
          style={{ color: "#ff0000", fontSize: "1rem", marginLeft: "0.5rem" }}
        >
          * Mandatory field
        </span>
      )}

      {isDuplicating && (
        <button
          className={styles.continueButton}
          onClick={handleContinueDuplicate}
          disabled={selectedFields.length === 0}
          style={{
            opacity: selectedFields.length === 0 ? 0.5 : 1,
            cursor: selectedFields.length === 0 ? "not-allowed" : "pointer",
          }}
          aria-label="Continue to duplicate fields"
        >
          <i className="bi bi-chevron-right"></i>
          Continue
        </button>
      )}

      {renderBottomActions()}
      {showConfirmDelete ? renderDeleteModal() : null}
    </div>
  );
};

export default TemplateView;
