import React from 'react';
import styles from './Loader.module.css';

const Loader = ({height="700", spinner="50", isPercent=false}) => {
  // Set the height unit based on isPercent
  const heightUnit = isPercent ? '%' : 'px';
  
  return (
    <div className={styles.container} style={{height: `${height}${heightUnit}`}}>
      <div className={styles.spinner} style={{width: `${spinner}px`, height: `${spinner}px`}}></div>
    </div>
  );
};

export default Loader;