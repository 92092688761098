/* eslint-disable react/prop-types */
import React from "react";
import styles from "./ActionDropdown.module.css";
import { Link, useLocation } from "react-router-dom";

const ActionDropdown = ({
  id,
  row,
  position,
  sectionType,
  handleClose,
  onViewManageTemplate,
  handleRemoveRow,
  route,
}) => {
  const isAdmin = localStorage.getItem("UserType") == 1;
  const handleRemove = () => {
    handleRemoveRow();
    onViewManageTemplate();
  };

  // console.log(route);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userID = queryParams.get("userID");

  const navigateUser = () => {
    if (sectionType == "Template") {
      return `/data-management/manageTemplate?section=2&userID=${userID}&manageTemplateID=${id}`;
    } else if (sectionType == "API") {
      console.log(id);
      return `/data-management/createRequest?section=3&userID=${userID}&api=${id[1]}`;
    } else {
      return `/data-management/viewData?userID=${userID}&fileId=${id}`;
    }
  };

  const checkForManage = () => {
    const status = row[3].toLowerCase();
    if (sectionType === "Template") return true;
    if (status === "something went wrong") return true;
    if (status === "complete") return true;
    return false;
  };

  const checkForEvidence = () => {
    if (sectionType === "Template") return true;
    if (route === "/data-management/listEvidence") return true;
    return false;
  };

  return (
    <div className={styles.dropdownContent}>
      {/* Close Button */}
      <button className={styles.closeButton} onClick={handleClose}>
        ×
      </button>

      <div className={styles.dropdownItems}>
        {checkForManage() || sectionType === "API" ? (
          <Link
            to={navigateUser()}
            className={styles.dropdownItem}
            onClick={onViewManageTemplate}
          >
            {`Manage ${sectionType}`}
          </Link>
        ) : null}

        {sectionType === "Data File" && checkForManage() ? (
          <hr className={styles.dropdownDivider} />
        ) : null}

        {sectionType === "Data File" ? (
          checkForEvidence() ? (
            <Link
              to={`/data-management/listEvidence?userID=${userID}&fileId=${id}`}
              className={styles.dropdownItem}
            >
              Upload Evidence
            </Link>
          ) : (
            <Link
              to={`${route}?userID=${userID}&fileId=${id}`}
              className={styles.dropdownItem}
            >
              Continue Table Creation
            </Link>
          )
        ) : null}

        {isAdmin || sectionType === "Data File" ? (
          <>
            <hr className={styles.dropdownDivider} />
            <div
              className={`${styles.dropdownItem} ${styles.removeItem}`}
              onClick={handleRemove}
            >
              Remove
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default ActionDropdown;
