import React from "react";
import { useContext, useEffect, useState } from "react";
import { Context } from "../contexts/Store";
import styles from "./playground.module.css";
import "bootstrap-icons/font/bootstrap-icons.css";

const Toolbar = () => {
  const { state, dispatch } = useContext(Context);
  const [tabs, setTabs] = useState(state?.tabs);
  const [activeTabId, setActiveTabId] = useState(state?.activeTabId);

  // Handle tab switching
  const switchTab = (tabId) => {
    setActiveTabId(tabId);
    const tab = tabs.find((t) => t.id === tabId);
    if (tab) {
      dispatch({ type: "SET_ACTIVE_TAB", payload: tabId });
    }
    toggleOverView(false);
  };


  const toggleOverView = (show) => {
    dispatch({ type: "SET_OVERVIEW", payload: show ? "shown" : "hidden" });
  };


  const addTab = () => {
    const newTabId = Math.max(...tabs.map((tab) => tab.id)) + 1;
    const newTab = {
      id: newTabId,
      name: `no_name_request`,
      method: "GET",
      url: "",
      params: "",
      payload: "",
      headers: [],
      responsePanelMinimized: false,
      auth: "",
      authHeader: "",
      authLocation: "header",
      requestHeaders: [],
      formSubmitted: false,
      responseUI: false,
      apiResponse: {},
      apiError: null,
    };
    const updatedTabs = [...tabs, newTab];
    setTabs(updatedTabs);
    setActiveTabId(newTabId);
    dispatch({ type: "ADD_TAB", payload: newTab });
    dispatch({ type: "SET_ACTIVE_TAB", payload: newTabId });
  };

  const closeTab = (tabId, e) => {
    e.stopPropagation();
    if (tabs.length === 1) return; // Prevent closing last tab
  
    // Filter out the tab to be closed and adjust the IDs of subsequent tabs
    const newTabs = tabs
      .filter((tab) => tab.id !== tabId)
      .map((tab) => ({
        ...tab,
        id: tab.id > tabId ? tab.id - 1 : tab.id, // Decrease ID for tabs with a higher ID than the closed tab
      }));
  
    setTabs(newTabs);
  
    // Dispatch REMOVE_TAB with adjusted tabId
    dispatch({ type: "REMOVE_TAB", payload: newTabs });

    // If the active tab was closed, switch to the last tab in the updated array
    if (activeTabId >= tabId) {
      const newActiveTab = newTabs[newTabs.length - 1];
      setActiveTabId(newActiveTab.id);
      dispatch({ type: "SET_ACTIVE_TAB", payload: newActiveTab.id });
    }
    
  };


  const getMethodLabel = (method) => {
    const methodStyles = {
      GET: { color: "#0f8e6c" },
      POST: { color: "#ac8a4f" },
      PUT: { color: "#2d64bb" },
      DELETE: { color: "#8e4b5c" },
      PATCH: { color: "#8e4b5c" },
    };

    return (
      <span
        className={styles.tab_name}
        style={{ ...methodStyles[method], fontWeight: "600" }}
      >
        {method === "DELETE" ? "DEL" : method}
      </span>
    );
  };

  useEffect(() => {
    setTabs(state.tabs);
    setActiveTabId(state.activeTabId);
  }, [state]);

  return (
    <div className={styles.toolbar}>
      <div className={styles.tabs_wrapper}>
        <div className={styles.all_tabs}>
          {tabs.map((tab) => (
            <div
              key={tab.id}
              onClick={() => switchTab(tab.id)}
              className={
                activeTabId === tab.id &&
                  (state.overviewTab === "hidden" || state.overviewTab === "")
                  ? styles.tab_active
                  : styles.tab
              }
            >
              {getMethodLabel(tab.method)}
              <span className={styles.tab_name}>{tab.name||"no_name_request"}</span>
              <button
                type="button"
                onClick={(e) => closeTab(tab.id, e)}
                className={styles.close_btn}
                title="Close tab"
              >
                <i className="bi bi-x"></i>
              </button>
            </div>
          ))}
        </div>
        <div className={styles.tab_actions}>
          <div className={styles.tab_sm} onClick={addTab} title="Add new tab">
            <button type="button" style={{background:"none",border:"none"}} className="btn btn-link p-0">
              <i className="bi bi-plus-lg"></i>
            </button>
          </div>
        </div>
      </div>
      {/* <div className={styles.env_menu_container}>
        <div className={styles.tab_sm_chevron}>
          <i className="bi bi-chevron-down"></i>
        </div>
        <div className={styles.env_menu}>
          <button type="button" className={styles.buttonWithImage}></button>
          <select
            name="environment"
            id="environment"
            className={styles.env_select}
          >
            <option value="">No Environment</option>
          </select>
          <div className={styles.vertical_line}></div>
          <button type="button" className={styles.buttonWithImage2}></button>
        </div>
      </div> */}
    </div>
  );
};

export default Toolbar;
