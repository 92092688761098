/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from "react";
import styles from "./WarningModal.module.css";

const WarningModal = ({
  onCancel = null,
  onSave = null,
  message = null,
  okButton = null,
  cancelButton = null,
  color = "#3F3F3F",
  icon
}) => {
  return (
    <div className={styles.overlay}>
      <div className={styles.modal}>
        <div className={styles.modalHeader}>
          {icon}
        </div>
        {message ? (
          <div className={styles.modalBody}>
            <p style={{ color: `${color}` }}>{message}</p>
          </div>
        ) : null}
        <div className={styles.modalFooter}>
          {cancelButton ? (
            <button className={styles.cancelButton} onClick={onCancel}>
              {cancelButton}
            </button>
          ) : null}
          {okButton ? (
            <button className={styles.saveButton} onClick={onSave}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.58984 16.58L13.1698 12L8.58984 7.41L9.99984 6L15.9998 12L9.99984 18L8.58984 16.58Z"
                  fill="#F7F7F8"
                />
              </svg>

              {okButton}
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default WarningModal;
