import React from "react";
import styles from "./ApiSection.module.css"; // Ensure this file exists
import { useHistory, useLocation } from "react-router-dom"; // Ensure react-router-dom is properly installed
import ShowDataTable from "../ShowDataTable/ShowDataTable"; // Ensure ShowDataTable exists and props match
import { useEffect } from "react";
import api from "../Api";
import { useState } from "react";
import Loader from "../Loader/Loader.js";

const headers = ["#", "Title", "Actions", "Last updated by", "Last updated on"];

const ApplyClass = {
  ".": 0,
  "#": 0,
  Title: 0,
  Actions: 0,
  "Last updated by": 0,
  "Last updated on": 0,
};

const ApiSection = () => {
  const navigate = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userID = queryParams.get("userID");
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const handleButtonClick = () => {
    navigate.push(`/data-management/createRequest?section=3&userID=${userID}`); // Ensure the route exists in your app
  };

  useEffect(() => {
    const fetchAPIs = async () => {
      setLoading(true);
      try {
        const response = await api.post("/getAllAPI", { userID });
        setData(response.data);
      } catch (err) {
        console.error("Error fetching APIs:", err);
        const errorMessage =
          err.response?.data?.error ||
          err.response?.data?.details ||
          err.message ||
          "Failed to fetch APIs";
        console.log(errorMessage);
      }
      setLoading(false);
    };

    if (userID) {
      fetchAPIs();
    }

  }, [userID]);

  const handleDelete = async (data) => {
    const id = data[1];
    try {
      const res = await api.delete(`/deleteAPI`, { data: { id } });
      setData((prevApis) => prevApis.filter((api) => api[0][1] !== id));
      console.log(res.data);
    } catch (err) {
      console.error("Delete API Error:", err);
      const errorMessage =
        err.response?.data?.error ||
        err.response?.data?.details ||
        err.message ||
        "Failed to delete API";
      console.log(errorMessage);
    }
  };

  if(isLoading) {
    return <Loader />;
  }
  return (
    <div className={styles.fileUploadCont}>
      <button className={styles.button} onClick={handleButtonClick}>
        <svg
          width="30"
          height="30"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 3V18H12V3H9ZM12 5L16 18L19 17L15 4L12 5ZM5 5V18H8V5H5ZM3 19V21H21V19H3Z"
            fill="#1232C3"
          />
        </svg>
        New API Request
      </button>
      <ShowDataTable
        showSorting={true}
        showMatching={false}
        showTitle={false}
        sectionType={"API"}
        onDelete={handleDelete}
        showSearchFilter={true}
        headerData={headers}
        extractedData={data}
        isDataEditable={false}
        isTemplateSection={false}
        ApplyClass={ApplyClass}
      />
    </div>
  );
};

export default ApiSection;
