import React, { useState } from "react";
import styles from "./UploadEvidence.module.css";
import { useHistory, useLocation, useSearchParams } from "react-router-dom";
import api from "../Api";
import UploadSuccess from "../UploadSuccess/UploadSuccess";
import { useEffect } from "react";
import Loader from "../Loader/Loader.js";

const UploadEvidence = ({ dropDownOptions }) => {
  const [files, setFiles] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(dropDownOptions);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileDate, setFileDate] = useState("");
  const [auditedOn, setAuditedOn] = useState("");
  const [auditedBy, setAuditedBy] = useState("");
  const [message, setMessage] = useState("");
  const [showSaved, setShowSaved] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userID = queryParams.get("userID");
  const fileId = parseInt(queryParams.get("fileId") || null);
  const navigate = useHistory();
  const setId = parseInt(queryParams.get("setId") || null);
  const [isLoading, setIsLoading] = useState(false);

  const [errors, setErrors] = useState({});

  // Handle file selection
  const handleFileSelect = (e) => {
    if (setId === null) return; // Don't allow file selection in edit mode
    
    const selectedFiles = Array.from(e.target.files);
    setFiles((prevFiles) => {
      const existingFileNames = prevFiles.map((file) => file.name);
      const filteredFiles = selectedFiles.filter(
        (file) => !existingFileNames.includes(file.name),
      );
      return [...prevFiles, ...filteredFiles];
    });
  };

  const fetchEvidenceSet = async () => {
    try {
      setIsLoading(true);
      const res = await api.post("/fetchEvidenceSet", { setId });
      setIsLoading(false);
      
      if (res.data && res.data.data) {
        // Format the files array to match what your component expects
        if (res.data.data.files && Array.isArray(res.data.data.files)) {
          const formattedFiles = res.data.data.files.map(file => ({
            name: file.file_name, // Use file_name as the name property
            path: file.file_path, // Store the file path if needed
            id: file.file_id,     // Store the file ID if needed
            // Add any other properties you might need from the file object
          }));
          
          setFiles(formattedFiles);
        }
        
        // Set other form fields
        setAuditedBy(res.data.data.audited_by || "");
        setAuditedOn(res.data.data.audited_on ? new Date(res.data.data.audited_on).toISOString().split('T')[0] : "");
        setSelectedLocation(res.data.data.selected_location || "");
        setFileDate(res.data.data.file_date ? new Date(res.data.data.file_date).toISOString().split('T')[0] : "");
        setFileName(res.data.data.set_name || "");
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (setId) {
      fetchEvidenceSet();
    }
  }, [setId]);

  // Handle drag and drop
  const handleDrop = (e) => {
    if (setId === null) return; // Don't allow drop in edit mode
    
    e.preventDefault();
    const droppedFiles = Array.from(e.dataTransfer.files);
    setFiles((prevFiles) => {
      const existingFileNames = prevFiles.map((file) => file.name);
      const filteredFiles = droppedFiles.filter(
        (file) => !existingFileNames.includes(file.name),
      );
      return [...prevFiles, ...filteredFiles];
    });
  };

  // Prevent default behavior for drag events
  const preventDefaults = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);
    setFilteredOptions(
      dropDownOptions?.filter((option) => option.toLowerCase().includes(value)),
    );
  };

  const handleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
    setSearchTerm("");
    setFilteredOptions(dropDownOptions);
  };

  const handleSelectLocation = (option) => {
    setSelectedLocation(option);
    setDropdownOpen(false);
  };

  // Handle Save
  const handleSave = async () => {
    const newErrors = {};

    // Validation checks - don't require files in edit mode
    if (files.length === 0 && !setId) {
      newErrors.files = "Please upload at least one file.";
    }

    if (!fileName) {
      newErrors.fileName = "File name is required.";
    }

    if (!fileDate) {
      newErrors.fileDate = "File date is required.";
    }

    // Removed validation for auditedOn and auditedBy since they're not mandatory

    if (!selectedLocation) {
      newErrors.selectedLocation = "Location is required.";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setErrors({});

    // Create FormData object
    const formData = new FormData();

    // Append non-file fields to the FormData
    formData.append("fileId", fileId);
    formData.append("userID", userID);
    formData.append("fileName", fileName);
    formData.append("fileDate", fileDate);
    formData.append("auditedOn", auditedOn);
    formData.append("auditedBy", auditedBy);
    formData.append("selectedLocation", selectedLocation);
    formData.append("setId", setId);

    // Only append files if not in edit mode
    if (!setId) {
      const temp = files?.forEach((file) => {
        formData.append("files[]", file); // Use 'files[]' to indicate an array of files
      });
    }
    
    setIsLoading(true);

    try {
      const res = await api.post("/saveEvidence", formData);

      setMessage(res.data.message);
      setShowSaved(true);
      console.log("Evidence saved successfully:", res.data);
    } catch (error) {
      console.error("Error during API call:", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (showSaved) {
      const timer = setTimeout(() => {
        setShowSaved(false);
      }, 3000);
      return () => {
        clearTimeout(timer)
        navigate.push(`/data-management/listEvidence?userID=${userID}&fileId=${fileId}`);
      };
    }
  }, [showSaved]);

  const MandatoryStar = () => <span className={styles.mandatoryStar}>*</span>;

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className={styles.uploadContainer}>
      <button className={styles.backButton} onClick={() => navigate.goBack()}>
        <svg
          width="8"
          height="12"
          viewBox="0 0 8 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 1.42L3.42 6L8 10.59L6.59 12L0.59 6L6.59 1.23266e-07L8 1.42Z"
            fill="#1232C3"
          />
        </svg>
        Back
      </button>

      {showSaved ? <UploadSuccess message={`${message}`} /> : null}

      {/* File Upload Section */}
      <div
        className={`${styles.uploadBox} ${setId ? styles.disabled : ''}`}
        onDragOver={setId ? null : preventDefaults}
        onDrop={setId ? null : handleDrop}
      >
        <p className={styles.uploadText}>
          Drag & drop files here or upload <MandatoryStar />
          <span className={styles.supportedTypes}>
            {" "}
            Supported files type : .pdf, .doc, .xls
          </span>
          {setId ? <span className={styles.disabledNote}> (Disabled in edit mode)</span>:null}
        </p>
        <label className={`${styles.uploadButton} ${setId ? styles.disabledButton : ''}`}>
          <input
            type="file"
            multiple
            className={styles.hiddenInput}
            onChange={handleFileSelect}
            disabled={setId === null}
          />
          <svg
            width="13"
            height="15"
            viewBox="0 0 13 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 0V1.875H13V0H0ZM0 9.375H3.71429V15H9.28571V9.375H13L6.5 2.8125L0 9.375Z"
              fill="#1232C3"
            />
          </svg>
          Upload
        </label>
      </div>

      {/* Error Message for File Upload */}
      {errors.files && <p className={styles.error}>{errors.files}</p>}

      {/* File List Info */}
      <div className={styles.filesInfo}>
        <a
          href="#"
          className={styles.filesUploaded}
          onClick={() => setIsOpen((prev) => !prev)}
        >
          {files?.length} files uploaded{" "}
          <span className={styles.arrow}>
            <svg
              style={{
                transform: isOpen ? "rotate(90deg)" : "rotate(270deg)",
                transition: "transform 0.3s ease",
              }}
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 1.42L3.42 6L8 10.59L6.59 12L0.59 6L6.59 1.23266e-07L8 1.42Z"
                fill="#1232C3"
              />
            </svg>
          </span>
        </a>
      </div>

      {/* File List */}
      {isOpen && files?.length > 0 && (
        <div className={styles.fileList}>
          <ul>
            {files?.map((file, index) => (
              <li key={index} className={styles.fileItem}>
                {file.name}
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Form Section */}
      <div className={styles.formSection}>
        <div className={styles.inputGroup}>
          <label>
            Name <MandatoryStar />
          </label>
          <input
            type="text"
            value={fileName}
            onChange={(e) => setFileName(e.target.value)}
          />
          {errors.fileName && <p className={styles.error}>{errors.fileName}</p>}
        </div>
        <div className={styles.inputGroup}>
          <label>
            Date of the File <MandatoryStar />
          </label>
          <input
            type="date"
            value={fileDate}
            onChange={(e) => setFileDate(e.target.value)}
            max={new Date().toISOString().split("T")[0]}
          />
          {errors.fileDate && <p className={styles.error}>{errors.fileDate}</p>}
        </div>
        <div className={styles.inputGroup}>
          <label>Audited on</label> {/* Removed MandatoryStar */}
          <input
            type="date"
            value={auditedOn}
            onChange={(e) => setAuditedOn(e.target.value)}
            max={new Date().toISOString().split("T")[0]}
          />
        </div>
        <div className={styles.inputGroup}>
          <label>Audited by</label> {/* Removed MandatoryStar */}
          <input
            type="text"
            value={auditedBy}
            onChange={(e) => setAuditedBy(e.target.value)}
          />
        </div>
        <div className={styles.inputGroup}>
          <label>
            Location <MandatoryStar />
          </label>
          <div className={styles.dropdown}>
            <div
              className={`${styles.locationDropdown}`}
              onClick={handleDropdown}
            >
              <p>{selectedLocation || "Select a location"}</p>
              <svg
                style={{
                  transform: dropdownOpen ? "rotate(90deg)" : "rotate(270deg)",
                  transition: "transform 0.3s ease",
                }}
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 1.42L3.42 6L8 10.59L6.59 12L0.59 6L6.59 1.23266e-07L8 1.42Z"
                  fill="#1232C3"
                />
              </svg>
            </div>
            {dropdownOpen && (
              <div className={styles.dropdown}>
                <ul
                  className={`${styles.dropdownList} ${dropdownOpen ? "" : styles.hidden}`}
                >
                  <input
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={handleSearch}
                    className={styles.searchInput}
                  />
                  <button
                    className={styles.closeButton}
                    onClick={handleDropdown}
                  >
                    &times;
                  </button>
                  {filteredOptions?.map((option, index) => (
                    <li
                      key={index}
                      className={styles.dropdownItem}
                      onClick={() => handleSelectLocation(option)}
                    >
                      {option}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          {errors.selectedLocation && (
            <p className={styles.error}>{errors.selectedLocation}</p>
          )}
        </div>
      </div>

      {/* Mandatory Fields Note */}
      <div className={styles.mandatoryNote}>
        <MandatoryStar /> <span>Indicates mandatory fields</span>
      </div>

      {/* Buttons */}
      <div className={styles.actionButtons}>
        <div className={styles.cancelAndSave}>
          <button className={styles.cancelButton} onClick={() => navigate.goBack()}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.46 12L19 17.54V19H17.54L12 13.46L6.46 19H5V17.54L10.54 12L5 6.46V5H6.46L12 10.54L17.54 5H19V6.46L13.46 12Z"
                fill="#1232C3"
              />
            </svg>
            Cancel
          </button>
          <button className={styles.saveButton} onClick={handleSave}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.58984 16.58L13.1698 12L8.58984 7.41L9.99984 6L15.9998 12L9.99984 18L8.58984 16.58Z"
                fill="#F7F7F8"
              />
            </svg>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default UploadEvidence;