import React from "react";
/* eslint-disable react/display-name */
import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import axios from "axios";
import { Context } from "../contexts/Store";
import { HistoryContext } from "../contexts/History";
import styles from "./playground.module.css";

axios.interceptors.request.use((request) => {
  request.customData = request.customData || {};
  request.customData.startTime = new Date().getTime();
  return request;
});

function updateEndTime(response) {
  response.customData = response.customData || {};
  response.customData.time =
    new Date().getTime() - response.config.customData.startTime;
  return response;
}

axios.interceptors.response.use(updateEndTime, (e) => {
  if (typeof e.response === "undefined") {
    const err =
      "A network error occurred. This could be a CORS issue or a dropped internet connection.\nOpen developer console to learn more.";
    return Promise.reject(err);
  }
  return Promise.reject(updateEndTime(e.response));
});

const AutoGrowInput = forwardRef((props, ref) => {
  const inputRef = useRef();
  useImperativeHandle(ref, () => ({
    focus() {
      inputRef.current.focus();
    },
  }));

  return (
    <div
      className="auto-grow-input"
      style={{width:"100%"
      }}
    >
      <input
        ref={inputRef}
        style={{width:"90%"}}
        placeholder="Enter URL or paste text"
        value={props.value}
        onChange={(event) => props.onChange(event.target.value)}
      />
    </div>
  );
});

const getMethodColor = (method) => {
  switch (method) {
    case "GET":
      return { color: "#0f8e6c", fontWeight: "600" };
    case "POST":
      return { color: "#ac8a4f", fontWeight: "600" };
    case "PUT":
      return { color: "#2d64bb", fontWeight: "600" };
    case "DELETE":
      return { color: "#8e4b5c", fontWeight: "600" };
    case "PATCH":
      return { color: "#8e4b5c", fontWeight: "600" };
    default:
      return { color: "#0f8e6c", fontWeight: "600" };
  }
};

const URLBox = () => {
  const { state, dispatch } = useContext(Context);
  const { dispatch: dispatchAPIEntry } = useContext(HistoryContext);

  const [url, setUrl] = useState(
    state.tabs[state.activeTabId - 1]?.url
  );

  const [queryParams, setQueryParams] = useState(
    state.tabs[state.activeTabId - 1]?.params
  );
  const [headers, setHeaders] = useState(null);
  const [method, setMethod] = useState(
    state.tabs[state.activeTabId - 1]?.method
  );
  const [btnDisabled, setBtnDisabled] = useState(
    state.tabs[state.activeTabId - 1]?.url?.length === 0
  );

  useEffect(() => {
    if (state.activeTabId) {
      setUrl(state.tabs[state.activeTabId - 1]?.url || "");
      setMethod(state.tabs[state.activeTabId - 1]?.method || "GET");
      setQueryParams(state.tabs[state.activeTabId - 1]?.params || "");
    }
    let qp = state.tabs[state.activeTabId - 1]?.params;
    if (state.authLocation === "qp") {
      const header = state.tabs[state.activeTabId - 1]?.authHeader.split(":");
      if (header.length === 2) {
        qp = qp
          ? `${qp}&${header[0]}=${header[1]}`
          : `${header[0]}=${header[1]}`;
      }
    } else {
      qp = state.tabs[state.activeTabId - 1]?.params;
    }
    setQueryParams(qp);

    let headerObject = {},
      authHeader = {};
    if (state.tabs[state.activeTabId - 1]?.requestHeaders?.length) {
      headerObject = {};
      state.tabs[state.activeTabId - 1].requestHeaders.forEach((header) => {
        const { key, value } = header;
        headerObject[key] = value;
      });
    }

    if (state.tabs[state.activeTabId - 1]?.auth) {
      authHeader = {};
      const auth = state.tabs[state.activeTabId - 1]?.authHeader.split(":");
      if (state.tabs[state.activeTabId - 1]?.authLocation === "header") {
        authHeader[auth[0]] = auth[1];
      } else {
        delete authHeader[auth[0]];
      }
    }
    setHeaders({ ...headerObject, ...authHeader });
  }, [state]);

  const inputboxRef = useRef();

  const handleChange = (e) => {
    setBtnDisabled(e.length <= 0);
    if (method === "") setMethod("GET");
    dispatch({ type: "SET_URL", payload: e });
    setUrl(e);
  };

  const handleMethodChange = (e) => {

    const newMethod = e.target.value;
    setMethod(newMethod);
    dispatch({ type: "SET_METHOD", payload: newMethod });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!method || !url) return;

    const fullUrl = queryParams ? `${url}${queryParams}` : url;
    if (!state.tabs[state.activeTabId - 1]?.responseUI) {
      dispatch({ type: "SET_RESPONSE_UI", payload: true });
    }
    dispatch({
      type: "SET_FORM_SUBMIT",
      payload: {
        method,
        url,
        params: state.tabs[state.activeTabId - 1]?.params,
        payload: state.tabs[state.activeTabId - 1]?.payload||null,
      },
    });

    
    axios({
      method,
      url: fullUrl,
      data: (state.tabs[state.activeTabId - 1]?.payload)?JSON.parse(state.tabs[state.activeTabId - 1]?.payload):null,
      headers,
    })
      .catch((e) => e )
      .then((res) => {
        if (typeof res === "string" || typeof res === "undefined") {
          dispatch({
            type: "SET_API_ERROR",
            payload: res,
          });
        } 
        else {
          dispatch({
            type: "SET_API_RESPONSE",
            payload: res,
          });
        } 
        const reqUrl = `${new Date().getTime()} : ${method} ${fullUrl}`;
        dispatchAPIEntry({ type: "ADD_NEW_ENTRY", payload: reqUrl });
      });
  };

  return (
    <div className={styles.url_box}>
      <form onSubmit={handleSubmit}>
        <select
          value={method}
          onChange={handleMethodChange}
          style={getMethodColor(method)} // Dynamically set the color of the select
        >
          <option value="GET" style={getMethodColor("GET")}>
            GET
          </option>
          <option value="POST" style={getMethodColor("POST")}>
            POST
          </option>
          <option value="PUT" style={getMethodColor("PUT")}>
            PUT
          </option>
          <option value="PATCH" style={getMethodColor("PATCH")}>
            PATCH
          </option>
          <option value="DELETE" style={getMethodColor("DELETE")}>
            DELETE
          </option>
        </select>
        <div
          className={styles.AutoGrowInputCont}
          onClick={() => {
            inputboxRef.current.focus();
          }}
        >
          <div className={styles.vertical_line2}></div>
          <AutoGrowInput
            value={url}
            ref={inputboxRef}
            onChange={handleChange}
            qp={queryParams}
          />
          <span>{queryParams}</span>
        </div>
        <div
          ref={(el) => {
            if (el) {
              el.style.setProperty("padding-inline", "0", "important");
            }
          }}
          style={{
            display: "flex",
            alignItems: "stretch",
            height: "100%",
            width: "100%",
            borderTopLeftRadius:"0.4rem",
            borderBottomLeftRadius:"0.4rem",
            borderLeft:"1px solid #ddd"
          }}
        >
          <button
            type="submit"
            className={styles.override_style}
            disabled={btnDisabled || state.tabs[state.activeTabId - 1]?.formSubmitted}
            style={{
              flex: 1,
              paddingInline: "0",
              borderRight: "none",
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              borderTopLeftRadius: "0.3rem",
              borderBottomLeftRadius: "0.3rem",
              height: "100%",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            Send
          </button>
          <button
            type="button"
            disabled={btnDisabled || state.tabs[state.activeTabId - 1]?.formSubmitted}
            className={styles.override_style}
            style={{
              width: "100%",
              paddingInline: "0",
              paddingLeft: "5px",
              borderLeft: "none",
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              marginLeft: "0",
            }}
            ref={(el) => {
              if (el) {
                el.style.setProperty("padding-inline", "0", "important"); // Making paddingInline important
              }
            }}
          >
            <i className="bi bi-chevron-down"></i>
          </button>
        </div>
      </form>
    </div>
  );
};

export default URLBox;
