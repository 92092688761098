import React from "react";
import { createContext, useReducer } from 'react';
import HistoryReducer from './HistoryReducer';

const initialState = [];
const History = ({ children }) => {
  const [apis, dispatch] = useReducer(
    HistoryReducer, initialState
  );

  return (
    <HistoryContext.Provider value={{ apis, dispatch }}>
      {children}
    </HistoryContext.Provider>
  );
};

export const HistoryContext = createContext(initialState);
export default History;
