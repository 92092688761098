/* eslint-disable react/prop-types */
import React from "react";
import styles from "./ListEvidence.module.css";
import ProgressBar from "../ShowDataTable/ProgressBar/ProgressBar";
import { Link,useHistory, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import api from "../Api";
import UploadSuccess from "../UploadSuccess/UploadSuccess";
import Loader from "../Loader/Loader.js";

const ListEvidence = ({ currentStep, onEdit }) => {
  const location = useLocation();
  // To parse the query parameters
  const queryParams = new URLSearchParams(location.search);
  const fileId = parseInt(queryParams.get("fileId") || null);
  const userID = parseInt(queryParams.get("userID") || null);
  const navigate = useHistory();
  const [extractedData, setExtractedData] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showActions, setShowActions] = useState(false);

  const headerData = [
    "Title",
    "Files",
    "Actions",
    "Last Updated By",
    "Last Updated On",
  ];

  const fetchEvidenceList = async () => {
    setIsLoading(true);
    try {
      const res = await api.post(`/fetchEvidenceList`, { fileId });

      setExtractedData(res.data);
    } catch (err) {
      console.error("Error fetching evidence list:", err);
    }
    setIsLoading(false);
  };

  const handleDelete = async (id, setId) => {
    try {
      const res = await api.post("/deleteEvidenceFile", { id, setId, userID });
      setExtractedData(res.data);
    } catch (err) {
      console.error("Error:", err);
    }
  };

  useEffect(() => {
    if (fileId && userID) {
      fetchEvidenceList();
    }
  }, []);

  const handleFinish = async () => {
    try {
      setIsLoading(true);
      const res = await api.post("/finishEvidenceUpload", {
        id: fileId,
        userID,
      });
      setIsLoading(false);
      console.log(res.data);
      setShowSuccess(true);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (showSuccess) {
      const timer = setTimeout(() => {
        setShowSuccess(false);
      }, 3000);
      return () => {
        clearTimeout(timer);
        navigate.push(`/data-management?userID=${userID}`);
      };
    }
  }, [showSuccess]);

  const handleRemove = async(data) => {
    try{
      setIsLoading(true);
      const res = await api.post("/removeEvidenceSet", { id: data.id,fileId });
      setExtractedData(res.data);
      setIsLoading(false);
    }
    catch(err){
      console.error(err);
    }
  };

  if (isLoading) {
    return <Loader />;
  }
  return (
    <div className={styles.container}>
      {currentStep && <ProgressBar currentStep={currentStep} />}
      <div className={styles.heading}>List of Evidences</div>
      <button
        className={styles.attachButton}
        onClick={() =>
          navigate.push(`/data-management/uploadEvidence?userID=${userID}&fileId=${fileId}`)
        }
      >
        <i className="bi bi-paperclip"></i> Attach Evidence
      </button>
      <span> Supported file types: .pdf, .doc, .xls</span>
      {showSuccess ? (
        <UploadSuccess message="Evidence upload is marked as completed" />
      ) : null}
      <table className={styles.table}>
        <thead>
          <tr>
            {headerData.map((header, index) => (
              <th key={index} className={styles.headerCell}>
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {extractedData.map((data, rowIndex) => (
            <tr key={rowIndex} className={styles.row}>
              <td className={styles.dataCell}>{data.title}</td>
              <td className={styles.dataCell}>
                <div className={styles.fileInfo}>
                  {data.files.map((file) => (
                    <div key={file.fileId} className={styles.fileRow}>
                      {/* File Icon */}
                      <div className={styles.file}>
                        <svg
                          width="20"
                          height="24"
                          viewBox="0 0 20 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14 0H6C4.9 0 4 0.9 4 2V18C4 19.1 4.9 20 6 20H18C19.1 20 20 19.1 20 18V6L14 0ZM18 18H6V2H13V7H18V18ZM2 4V22H18V24H2C0.9 24 0 23.1 0 22V4H2ZM8 10V12H16V10H8ZM8 14V16H13V14H8Z"
                            fill="#5037E9"
                          />
                        </svg>

                        {/* File Name with Clickable Link */}
                        <a
                          href={file.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={styles.fileName}
                        >
                          {file.name}
                        </a>
                      </div>

                      {/* Delete Icon */}
                      <svg
                        width="12"
                        height="16"
                        viewBox="0 0 12 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className={styles.icon}
                        onClick={() => handleDelete(file.fileId, data.id)}
                        style={{ cursor: "pointer" }}
                      >
                        <path
                          d="M0.857143 14.2222C0.857143 14.6937 1.03775 15.1459 1.35925 15.4793C1.68074 15.8127 2.11677 16 2.57143 16H9.42857C9.88323 16 10.3193 15.8127 10.6408 15.4793C10.9622 15.1459 11.1429 14.6937 11.1429 14.2222V3.55556H0.857143V14.2222ZM2.57143 5.33333H9.42857V14.2222H2.57143V5.33333ZM9 0.888889L8.14286 0H3.85714L3 0.888889H0V2.66667H12V0.888889H9Z"
                          fill="#5037E9"
                        />
                      </svg>
                    </div>
                  ))}
                </div>
              </td>
              <td className={styles.dataCell}>
                <>
                  <button
                    className={styles.button}
                    style={{
                      paddingLeft: `"2rem !important"}`,
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowActions(true);
                    }}
                  >
                    <svg
                      width="20"
                      viewBox="0 0 16 4"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="2" cy="2" r="2" fill="#786ACD" />
                      <circle cx="8" cy="2" r="2" fill="#786ACD" />
                      <path
                        d="M16 2C16 3.10457 15.1046 4 14 4C12.8954 4 12 3.10457 12 2C12 0.89543 12.8954 0 14 0C15.1046 0 16 0.89543 16 2Z"
                        fill="#786ACD"
                      />
                    </svg>
                  </button>
                  {showActions ? (
                    <div className={styles.dropdownContent}>
                      <button
                        className={styles.closeButton}
                        onClick={() => setShowActions(false)}
                      >
                        ×
                      </button>

                      <div className={styles.dropdownItems}>
                        <Link
                          to={`/data-management/uploadEvidence?userID=${userID}&fileId=${fileId}&setId=${data.id}`}
                          className={styles.dropdownItem}
                        >
                          {`Manage Evidence Set`}
                        </Link>

                        <hr className={styles.dropdownDivider} />
                        <div
                          className={`${styles.dropdownItem} ${styles.removeItem}`}
                          onClick={() => handleRemove(data)}
                        >
                          Remove
                        </div>
                      </div>
                    </div>
                  ) : null}
                </>
              </td>
              <td className={styles.dataCell}>{data.lastUpdatedBy}</td>
              <td className={styles.dataCell}>{data.lastUpdatedOn}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className={styles.complete}>
        <button
          className={styles.finish}
          onClick={handleFinish}
          disabled={
            extractedData.length === 0 ||
            extractedData.every((item) => item.files.length === 0)
          }
        >
          <i className="bi bi-check-lg"></i>
          Finish Upload
        </button>
      </div>
    </div>
  );
};

export default ListEvidence;
