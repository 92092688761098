/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useRef, useState } from "react";
import CustomDropdown from "./CustomDropdown/CustomDropdown"; // Import the custom dropdown
import styles from "./FileUpload.module.css";
import uploadIcon from "../icons/upload.svg";
import api from "../Api";
import { useEffect } from "react";
import WarningModal from "../WarningModal/WarningModal";
import ShowDataTable from "../ShowDataTable/ShowDataTable";
import Loader from "../Loader/Loader.js";
import { useLocation } from "react-router-dom";

const normalHeaders = [
  "#",
  "Title",
  "Category",
  "Status",
  "Actions",
  "Last updated by",
  "Last updated on",
];

const isAdmin = localStorage.getItem("UserType") == 1;

const FileUpload = ({ categories, handleFileUpload, applyClass }) => {
  const location = useLocation();
  const [data, setData] = useState(null);
  const [fileInfo, setFileInfo] = useState(null);
  const modifiedCategories = categories
    ? [...categories, { name: "Unknown Data Category" }]
    : [{ name: "Unknown Data Category" }];
  const [categorySelected, setCategorySelected] = useState(null);
  const [templateSelected, setTemplateSelected] = useState(null);
  const [mappingKeySelected, setMappingKeySelected] = useState(null);
  const [templates, setTemplates] = useState(null);
  const [mappingKeys, setMappingKeys] = useState(null);
  const [showWarning, setShowWarning] = useState(false);
  const fileInputRef = useRef(null);
  const queryParams = new URLSearchParams(location.search);
  const userID = queryParams.get("userID");
  const [type, setType] = useState("0");
  const [isLoading, setIsLoading] = useState(false);
  const [loader1, setLoader1] = useState(false);
  const [loader2, setLoader2] = useState(false);

  const fetchTemplatesForCategory = async () => {
    setLoader1(true);
    try {
      const res = await api.post(
        `/getTemplatesForCategory?userID=${userID}`,
        categorySelected,
      );
      setTemplates([...res.data, { name: "No template available" }]);
    } catch (err) {
      console.log(err);
    }
    setLoader1(false);
  };

  const fetchMappingKey = async () => {
    setLoader2(true);
    try {
      const res = await api.post("/getMappingKeyForTemplate", templateSelected);
      setMappingKeys([...res.data, { name: "No mapping key available" }]);
    } catch (err) {
      console.log(err);
    }
    setLoader2(false);
  };

  useEffect(() => {
    if (templateSelected) {
      if (templateSelected.name === "No template available") return;
      fetchMappingKey();
    }
  }, [templateSelected]);

  useEffect(() => {
    if (categorySelected) {
      if (categorySelected.name === "Unknown Data Category") return;
      fetchTemplatesForCategory(categorySelected);
    }
  }, [categorySelected]);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const data = {
        selectedFile,
        categorySelected,
        templateSelected,
        mappingKeySelected,
      };
      handleFileUpload(data);
    }
    e.target.value = null;
  };

  const formatDate = (isoDate) => {
    return new Date(isoDate).toLocaleString("en-US", {
      year: "numeric",
      month: "short", // "Feb"
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true, // Use 12-hour format with AM/PM
    });
  };

  const fetchUploadedFiles = async () => {
    setIsLoading(true);
    try {
      const res = await api.post("/getUploadedFiles", { userID, isAdmin });
      // console.log(res.data);
      const transformedData = res.data.files.map((file) => {
        return [
          file.file_id,
          file.filename,
          file.category_name || "Unknown",
          file.status,
          "Actions",
          file.updated_by || "Admin",
          formatDate(file.updated_at),
        ];
      });
      setData(res.data.files);
      setFileInfo(transformedData);
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchUploadedFiles();
  }, []);

  const handleUploadClick = () => {
    if (
      categorySelected?.name === "Unknown Data Category" ||
      templateSelected.name === "No template available"
    ) {
      setShowWarning(true);
      return;
    }
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDelete = async (id) => {
    setIsLoading(true);
    try {
      const res = await api.post("/deleteUploadedFile", { id, userID });
      const transformedData = res.data.files.map((file) => {
        return [
          file.file_id,
          file.filename,
          file.category_name || "Unknown",
          file.status,
          "Actions",
          file.updated_by || "Admin",
          formatDate(file.updated_at),
        ];
      });
      setData(res.data.files);
      setFileInfo(transformedData);
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  };

  if (isLoading||loader1||loader2) {
    return <Loader />;
  }
  return (
    <div className={styles.fileUploadCont}>
      <p className={styles.disc}>
        <b>Tip :</b> Select data category, data template, and mapping key (if
        applicable) to upload data files.
      </p>
      <div className={styles.warningCont}>
        <div className={styles.warningBox}></div>
      </div>
      <div className={styles.dropdownContainer}>
        <CustomDropdown
          label="Select Data Category"
          options={modifiedCategories}
          header="Select data category"
          whichDropdown="1"
          type={type}
          setType={setType}
          value={categorySelected?.name || ""}
          onSelect={(value) => {
            setCategorySelected(value);
            setTemplateSelected(null);
            setMappingKeySelected(null);
          }}
          disabled={false} // First dropdown is always enabled
        />

        <CustomDropdown
          label="Select Data Template"
          options={templates}
          header="Select data template"
          whichDropdown="2"
          type={type}
          setType={setType}
          value={templateSelected?.name || ""}
          onSelect={(value) => {
            setTemplateSelected(value);
            setMappingKeySelected(null);
          }}
          disabled={
            !categorySelected ||
            categorySelected?.name === "Unknown Data Category"
          }
        />

        <CustomDropdown
          label="Select Mapping Key"
          options={mappingKeys}
          header="Select mapping key"
          whichDropdown="3"
          type={type}
          setType={setType}
          value={mappingKeySelected?.name || ""}
          onSelect={(value) => setMappingKeySelected(value)}
          disabled={
            !templateSelected ||
            templateSelected?.name === "No template available"
          } // Disable until a template is selected
        />
      </div>

      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />

      {showWarning ? (
        <WarningModal
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="#D6746F"
              className="bi bi-info-circle"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
            </svg>
          }
          onCancel={() => setShowWarning(false)}
          onSave={() => fileInputRef.current.click()}
          okButton="Continue"
          cancelButton="Cancel"
          color="#D6746F"
          message="Request admin to create data category and template if it doesn’t exist. Proceed only if unknown."
        />
      ) : null}

      <button
        className={styles.uploadButton}
        disabled={
          !(
            categorySelected?.name === "Unknown Data Category" ||
            templateSelected?.name
          )
        }
        onClick={handleUploadClick}
      >
        <img src={uploadIcon} alt="Upload Icon" className={styles.uploadIcon} />
        Upload
      </button>

      <ShowDataTable
        // Configuration flags
        showBackButton={false}
        showSaveCancelButton={false}
        showSaveButton={false}
        showSorting={true}
        showMatching={false}
        showTitle={false}
        showSearchFilter={true}
        showMappingTip={false}
        shouldSlice={true}
        sectionType={"Data File"}
        onDelete={handleDelete}
        // Step & state management
        currentStep={0}
        isFileUploaded={1}
        isDataEditable={false}
        isTemplateSection={false}
        isHomeSection={true}
        // Data
        categories={categories?.map((cat) => cat?.name)}
        fileInfo={data}
        headerData={normalHeaders}
        extractedData={fileInfo}
        // // Styling
        ApplyClass={applyClass}
      />
    </div>
  );
};

export default FileUpload;
