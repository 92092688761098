import React from "react";
import { createContext, useEffect, useReducer } from "react";
import StoreReducer from "./StoreReducer";

const initialState = {
  overviewTab: "hidden",
  sideDrawerOpened: false,
  sideDrawerTab: "history",
  infoPanelOpened: false,
  splitView: "H",
  activeTabId: 1,
  tabs: [
    {
      id: 1,
      name: "no_name_request",
      method: "GET",
      url: "",
      params: "",
      payload: "",
      headers: [],
      responsePanelMinimized: false,
      auth: "",
      authHeader: "",
      authLocation: "header",
      requestHeaders: [],
      formSubmitted: false,
      responseUI: false,
      apiResponse: {},
      apiError: null,
    },
  ],
};

const Store = ({ children }) => {
  const [state, dispatch] = useReducer(
    StoreReducer, initialState
  );
  
  useEffect(() => {
    if (state.activeTab) {
      dispatch({ type: "SET_FORM_DATA", payload: state.activeTab.formData });
    }
  }, [state.activeTab]);
  return (
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  );
};

export const Context = createContext(initialState);
export default Store;
