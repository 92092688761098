import React from "react";
import { useContext } from "react";
import Playground from "./playground/Playground";
import Store, { Context } from "./contexts/Store";
import History from "./contexts/History";
import classes from "./CreateRequest.module.css";
import { useHistory, useLocation } from "react-router-dom";
import { useEffect } from "react";
import api from "../Api";

const AppInterface = () => {
  // Access the context state
  const { state, dispatch } = useContext(Context);
  const location  = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("api");

  const handleDone = async () => {
    console.log(state);
  };

  useEffect(() => {
    const fetchAPI = async () => {
      try {
        const res = await api.post("/getApi", { id });
        dispatch({ type: "SET_API", payload: JSON.parse(res.data) });
      } catch (err) {
        console.log(err);
      }
    };
    if (id) {
      fetchAPI();
    }
  }, []);

  return (
    <div className={classes.reqPage}>
      <div className={classes.apiReq}>{state && <Playground />}</div>
      <button className={classes.doneBut} onClick={() => handleDone()}>
        <i className="bi bi-check-lg"></i>Done
      </button>
    </div>
  );
};

// Main component that provides context and renders AppInterface
const CreateRequest = () => {
  const navigate = useHistory();
  const handleBack = () => {
    navigate.goBack();
  };
  return (
    <Store>
      <History>
        <button className={classes.backButton} onClick={handleBack}>
          <svg
            width="8"
            height="12"
            viewBox="0 0 8 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 1.42L3.42 6L8 10.59L6.59 12L0.59 6L6.59 1.23266e-07L8 1.42Z"
              fill="#1232C3"
            />
          </svg>
          Back
        </button>
        <AppInterface />
      </History>
    </Store>
  );
};

export default CreateRequest;
