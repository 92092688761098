/* eslint-disable react/prop-types */
import React from 'react';
import { useState, useEffect } from "react";
import api from "../Api"; // Replace with your actual API service
import ShowDataTable from "../ShowDataTable/ShowDataTable";
import TemplateUploadForm from "../Template/TemplateUploadForm/TemplateUploadForm";
import UploadSuccess from "../UploadSuccess/UploadSuccess";
import styles from "./TemplateTable.module.css";
import WarningModal from "../WarningModal/WarningModal";
import Loader from "../Loader/Loader.js";
import { useLocation } from 'react-router-dom';

const TemplateSectionHeaders = [
  "#",
  "Title",
  "Category",
  "Status",
  "Actions",
  "Last updated by",
  "Last updated on",
];

const TemplateComponent = ({ ApplyClass, categories }) => {
  const location = useLocation();
  const [message, setMessage] = useState("");
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [extractedData, setExtractedData] = useState(null);
  const [showUpdated, setShowUpdated] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [selectedTemplateInfo, setSelectedTemplateInfo] = useState(null);
  const queryParams = new URLSearchParams(location.search);
  const userID = queryParams.get("userID");
  const [errMessage, setErrMessage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [overwrite, setOverwrite] = useState(null);
  const [updatedTemplateInfo, setUpdatedTemplateInfo] = useState(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState(0);
  const [deleteTemplateId, setDeleteTemplateId] = useState(null);
  const [showUploadForm, setShowUploadForm] = useState(false);
  const [loading, setLoading] = useState(false);
  // const dispatch = useDispatch();

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setShowUploadForm(true);
      // console.log(file);
      // Reset the file input value so the same file can be uploaded again
      event.target.value = null;
    }
  };

  const handleSave = async (updatedInfo) => {
    setUpdatedTemplateInfo(updatedInfo);
    setLoading(true);
    try {
      let url;
      let payload;
      let method;

      if (selectedFile) {
        setMessage("uploaded");
        url = `/uploadTemplate?userID=${userID}`;
        payload = new FormData();
        payload.append("file", selectedFile);
        payload.append("updatedInfo", JSON.stringify(updatedInfo));
        // Remove the Content-Type header - let the browser set it automatically
        method = "POST";
      } else {
        setMessage("updated");
        url = `/updateTemplateInfo?templateID=${selectedTemplateId}&userID=${userID}`;
        payload = updatedInfo;
        method = "PUT";
      }

      // Make the API request with conditional headers
      const response = await api({
        method,
        url,
        data: payload,
        headers: selectedFile
          ? undefined
          : { "Content-Type": "application/json" },
      });

      // Handle the response
      const data = response.data;
      console.log(data);
      setShowUpdated(true);
      setSelectedTemplateId(null);
      setSelectedTemplateInfo(null);
      setSelectedFile(null);
      await fetchUserTemplates(userID);
      setErrMessage(null);
      setOverwrite(null);
      setUpdatedTemplateInfo(null);
    } catch (err) {
      // Handle errors
      setErrMessage(err?.response?.data?.message);
      if (err?.response?.data?.showOverwrite) {
        setOverwrite(err.response.data);
        setShowUploadForm(false);
      }
      console.log(err?.response?.data);
    }
    setLoading(false);
  };

  const onCancel = () => {
    setErrMessage(null);
    setOverwrite(null);
    setShowUploadForm(true);
  };

  const onSave = async () => {
    setLoading(true);
    try {
      // Check if a file is selected
      if (!selectedFile) {
        throw new Error("No file selected. Please select a file to upload.");
      }
      setMessage("uploaded");
      // Delete the existing template if overwrite is required
      if (overwrite?.templateID) {
        const deleteResponse = await api.delete(`/deleteTemplate`, {
          params: { templateID: overwrite.templateID, userID },
        });

        console.log("Template deleted successfully:", deleteResponse.data);
      }

      // Prepare payload for file upload
      const payload = new FormData();
      payload.append("file", selectedFile); // Attach the file
      payload.append("updatedInfo", JSON.stringify(updatedTemplateInfo)); // Include updatedInfo as a JSON string
      const config = { headers: { "Content-Type": "multipart/form-data" } }; // FormData headers

      // Make the API request for file upload
      const response = await api.post(
        `/uploadTemplate?userID=${userID}`,
        payload,
        config,
      );

      await fetchUserTemplates();

      console.log("File uploaded successfully:", response.data);
      setShowUpdated(true);
      setSelectedTemplateId(null);
      setSelectedTemplateInfo(null);
      setSelectedFile(null);
      setErrMessage(null);
      setOverwrite(null);
      setUpdatedTemplateInfo(null);
    } catch (err) {
      console.error("An error occurred:", err?.response?.data || err.message);

      // Optionally, display the error message to the user
      setErrMessage(
        err?.response?.data?.message || "An unexpected error occurred.",
      );
      setShowWarning(true);
    }
    setLoading(false);
  };

  const ErrorMessageComponent = () => (
    <div className={styles.formWrap}>
      <div className={styles.errorContainer}>
        <p className={styles.errorMessage}>{overwrite?.message}</p>
        <div className={styles.buttonGroup}>
          <button className={styles.cancelButton} onClick={onCancel}>
            Cancel
          </button>
          <button className={styles.saveButton} onClick={onSave}>
            <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.589844 10.58L5.16984 6L0.589844 1.41L1.99984 0L7.99984 6L1.99984 12L0.589844 10.58Z"
                fill="#F7F7F8"
              />
            </svg>
            Save
          </button>
        </div>
      </div>
    </div>
  );

  const handleDeleteCancel = () => setShowConfirmDelete(false);
  const handleDeleteTemplate = async () => {
    setLoading(true);
    try {
      const templateID = deleteTemplateId;
      // Send delete request to the API
      const res = await api.delete(`/deleteTemplate`, {
        params: { templateID, userID },
      });

      // Check if the response was successful
      if (res.status === 200) {
        // Refresh the user's templates
        await fetchUserTemplates();
        // Provide success feedback
        setDeleteTemplateId(null);
        setShowConfirmDelete(0);
        setShowUpdated(1);
        setMessage("removed");
      }
    } catch (err) {
      // Handle errors
      setErrMessage(
        err?.response?.data?.message || "An unexpected error occurred.",
      );
      setShowWarning(true);
      console.error("Error deleting template:", err);
    } finally {
      setLoading(false);
      // Close the delete confirmation modal
      setShowConfirmDelete(false);
    }
  };

  const renderDeleteModal = () => (
    <WarningModal
      icon={
        <i
          className="bi bi-exclamation-triangle-fill"
          style={{ color: "#D6746F" }}
        ></i>
      }
      message={`Are you sure you want to delete the template?`}
      okButton="Delete"
      cancelButton="Cancel"
      onCancel={handleDeleteCancel}
      onSave={handleDeleteTemplate}
    />
  );

  const formatDate = (isoDate) => {
    return new Date(isoDate).toLocaleString("en-US", {
      year: "numeric",
      month: "short", // "Feb"
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true, // Use 12-hour format with AM/PM
    });
  };

  const fetchUserTemplates = async () => {
    setLoading(true);
    try {
      await api
        .get(`/getAllTemplates`) // Send userID as query param
        .then((response) => {
          const data = response.data; // Assuming API returns template details in 'data'
          // console.log(data);
          const transformedData = data.map((template) => {
            return [
              template?.template_id,
              template?.name,
              template?.category,
              template?.status === 1 ? "Active" : "Inactive",
              "Actions",
              template?.updated_by, // Last updated by
              formatDate(template.updated_at), // Last updated on
            ];
          });
          setExtractedData(transformedData); // Set state with fetched data
        })
        .catch((error) => {
          console.error("Error fetching user templates:", error);
        });
    } catch (error) {
      console.error("Error fetching templates:", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch user templates from the backend
  useEffect(() => {
    if (userID) {
      fetchUserTemplates();
    }
  }, [userID]);

  useEffect(() => {
    if (selectedTemplateId) {
      api
        .get(`/getTemplateInfo?templateID=${selectedTemplateId}`)
        .then((response) => {
          const data = response.data;
          setSelectedTemplateInfo(data[0]);
        })
        .catch((error) => {
          // Handle errors
          console.error("Error fetching template info:", error);

          // Optional: Display an error message to the user
          console.log(
            "Failed to fetch template information. Please try again.",
          );
        });
    }
  }, [selectedTemplateId]);

  useEffect(() => {
    if (showUpdated) {
      const timer = setTimeout(() => {
        setShowUpdated(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [showUpdated]);

  useEffect(() => {
    if (selectedTemplateInfo) {
      setShowUploadForm(true);
    }
  }, [selectedTemplateInfo]);

  if (loading) {
    return <Loader></Loader>
  }

  if (!extractedData) {
    return null;
  }

  return (
    <div className={styles.cont}>
      <button
        className={styles.button}
        style={{ marginLeft: "1.1rem", marginBottom: "0rem" }}
        onClick={() => document.getElementById("fileInput").click()}
      >
        <svg
          width="30"
          height="30"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 5V6.875H19V5H6ZM6 14.375H9.71429V20H15.2857V14.375H19L12.5 7.8125L6 14.375Z"
            fill="#1232C3"
          />
        </svg>
        Upload Template
      </button>
      <input
        id="fileInput"
        type="file"
        accept=".csv, .xls, .xlsx"
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
      <ShowDataTable
        showSorting={true}
        showMatching={false}
        headerData={TemplateSectionHeaders}
        showTitle={false}
        extractedData={extractedData}
        isTemplateSection={true}
        sectionType={"Template"}
        categories={categories?.map((cat) => cat?.name)}
        setSelectedTemplateId={setSelectedTemplateId}
        onDelete={(templateId) => {
          setShowConfirmDelete(true);
          setDeleteTemplateId(templateId);
        }}
        ApplyClass={ApplyClass}
        showSearchFilter={true}
      />

      {showWarning ? (
        <WarningModal
          icon={
            <i
              className="bi bi-exclamation-triangle-fill"
              style={{ color: "#D6746F" }}
            ></i>
          }
          message={errMessage}
          okButton="OK"
          onSave={() => {
            setShowWarning(false);
            setErrMessage("");
          }}
        />
      ) : null}

      {showConfirmDelete ? renderDeleteModal() : null}
      {overwrite ? ErrorMessageComponent() : null}

      {selectedTemplateInfo || selectedFile ? (
        <TemplateUploadForm
          initialData={selectedTemplateInfo}
          errMessage={errMessage}
          categories={categories}
          onSave={handleSave}
          showUploadForm={showUploadForm}
          onCancel={() => {
            setSelectedTemplateId(null);
            setSelectedTemplateInfo(null);
            setErrMessage(null);
            setSelectedFile(null);
            setOverwrite(null);
            setErrMessage("");
            setUpdatedTemplateInfo(null);
          }}
        />
      ) : null}

      {showUpdated ? (
        <UploadSuccess message={`Template ${message} successfully`} />
      ) : null}
    </div>
  );
};

export default TemplateComponent;
