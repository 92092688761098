import React from "react";
import { useContext, useState } from "react";
import LineLoader from "./LineLoader";
import HeadersTable from "./HeadersTable";
import { Context } from "../../contexts/Store";
import ResponseBody from "./ResponseBody";
import style from "./response.module.css";
import * as XLSX from "xlsx";

const ResponseWrapper = () => {
  const { state, dispatch } = useContext(Context);
  const [respView, setRespView] = useState("body");
  const [viewAs, setViewAs] = useState("pretty");
  const [viewMode, setViewMode] = useState("json");
  const [wordWrap, setWordWrap] = useState(false);

  const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  function getResponseSize(response) {
    return niceBytes(
      JSON.stringify(response.data).length +
        JSON.stringify(response.headers).length
    );
  }

  function niceBytes(x) {
    let l = 0,
      n = parseInt(x, 10) || 0;
    while (n >= 1024 && ++l) {
      n = n / 1024;
    }
    return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l];
  }

  const cancelRequest = () => {
    dispatch({ type: "CANCEL_FORM_SUBMIT" });
  };

  async function copyAPIResponse() {
    if (navigator.clipboard) {
      await navigator.clipboard.writeText(
        JSON.stringify(
          state.tabs[state.activeTabId - 1]?.apiResponse.data,
          null,
          2
        )
      );
    }
  }

  const handleExport = (format) => {
    const response = state.tabs[state.activeTabId - 1]?.apiResponse.data;
    if (!response) return;

    if (format === "plainText") {
      const blob = new Blob([JSON.stringify(response, null, 2)], {
        type: "text/plain",
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "response.txt";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } else if (format === "excel") {
      const worksheet = XLSX.utils.json_to_sheet([response]);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Response");
      XLSX.writeFile(workbook, "api_response.xlsx");
    }
  };

  return (
    <>
      {state.formSubmitted && (
        <>
          <LineLoader />
          <div className={style.overlay}>
            <div>
              <p>Sending request...</p>
              <button type="button" onClick={cancelRequest}>
                Cancel
              </button>
            </div>
          </div>
        </>
      )}
      {JSON.stringify(state.tabs[state.activeTabId - 1]?.apiResponse) !==
      "{}" ? (
        <div
          className={
            state.splitView === "V" ? style.wrapper_full : style.wrapper
          }
        >
          <div className={style.top}>
            <div className={style.header}>
              <ul className={style.payload_types}>
                <li
                  onClick={(e) => setRespView("body")}
                  className={
                    respView === "body"
                      ? style.payload_tab_active
                      : style.payload_tab
                  }
                >
                  Body
                </li>
                <li className={style.payload_tab_disabled}>Cookies</li>
                <li
                  onClick={(e) => setRespView("headers")}
                  className={
                    respView === "headers"
                      ? style.payload_tab_active
                      : style.payload_tab
                  }
                >
                  Headers{" "}
                  {state.tabs[state.activeTabId - 1]?.apiResponse.headers && (
                    <span>
                      (
                      {
                        Object.entries(
                          state.tabs[state.activeTabId - 1]?.apiResponse.headers
                        ).length
                      }
                      )
                    </span>
                  )}
                </li>
                <li className={style.payload_tab_disabled}>Test results</li>
              </ul>
              <div className={style.resp_meta}>
                <i className="feather-globe"></i>
                <div>
                  Status:{" "}
                  <span>
                    {state.tabs[state.activeTabId - 1]?.apiResponse.status}{" "}
                    <em>
                      {
                        state.tabs[state.activeTabId - 1]?.apiResponse
                          .statusText
                      }
                    </em>{" "}
                  </span>
                </div>
                <div>
                  Time:{" "}
                  <span>
                    {
                      state.tabs[state.activeTabId - 1]?.apiResponse.customData
                        .time
                    }
                    ms
                  </span>
                </div>
                <div>
                  Size:{" "}
                  <span>
                    {getResponseSize(
                      state.tabs[state.activeTabId - 1]?.apiResponse
                    )}
                  </span>
                </div>
                <div>
                  {" "}
                </div>
              </div>
            </div>
            {respView === "body" && (
              <div className={style.more}>
                <div className={style.tabs}>
                  <div className={style.tab_group}>
                    <div
                      onClick={() => setViewAs("pretty")}
                      className={viewAs === "pretty" ? style.tab_selected : ""}
                    >
                      Pretty
                    </div>
                    <div
                      onClick={() => setViewAs("raw")}
                      className={viewAs === "raw" ? style.tab_selected : ""}
                    >
                      Raw
                    </div>
                  </div>
                  {viewAs === "pretty" && (
                    <>
                      <div className={style.tab_group}>
                        <select
                          onChange={(e) => handleExport(e.target.value)}
                          defaultValue=""
                        >
                          <option value="" disabled>
                            Export as
                          </option>
                          <option value="plainText">Plain Text</option>
                          <option value="excel">Excel</option>
                        </select>
                        <i className="feather-download"></i>
                      </div>
                      <div
                        className={style.tab_group}
                        style={{
                          height: "30px",
                          width: "45px",
                          padding: "none",
                        }}
                      >
                        <button
                          className={wordWrap ? style.button_selected : ""}
                          onClick={() => setWordWrap(!wordWrap)}
                        >
                          <svg
                            width="20px"
                            height="20px"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g
                              id="SVGRepo_tracerCarrier"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></g>
                            <g id="SVGRepo_iconCarrier">
                              {" "}
                              <path
                                d="M2.75 5C2.33579 5 2 5.33579 2 5.75C2 6.16421 2.33579 6.5 2.75 6.5H21.25C21.6642 6.5 22 6.16421 22 5.75C22 5.33579 21.6642 5 21.25 5H2.75Z"
                                fill="#212121"
                              ></path>{" "}
                              <path
                                d="M2.75 11.5C2.33579 11.5 2 11.8358 2 12.25C2 12.6642 2.33579 13 2.75 13H19C20.3807 13 21.5 14.1193 21.5 15.5C21.5 16.8807 20.3807 18 19 18H14.5607L15.2803 17.2803C15.5732 16.9874 15.5732 16.5126 15.2803 16.2197C14.9874 15.9268 14.5126 15.9268 14.2197 16.2197L12.2197 18.2197C11.9268 18.5126 11.9268 18.9874 12.2197 19.2803L14.2197 21.2803C14.5126 21.5732 14.9874 21.5732 15.2803 21.2803C15.5732 20.9874 15.5732 20.5126 15.2803 20.2197L14.5607 19.5H19C21.2091 19.5 23 17.7091 23 15.5C23 13.2909 21.2091 11.5 19 11.5H2.75Z"
                                fill="#212121"
                              ></path>{" "}
                              <path
                                d="M2 18.75C2 18.3358 2.33579 18 2.75 18H9.25C9.66421 18 10 18.3358 10 18.75C10 19.1642 9.66421 19.5 9.25 19.5H2.75C2.33579 19.5 2 19.1642 2 18.75Z"
                                fill="#212121"
                              ></path>{" "}
                            </g>
                          </svg>
                        </button>
                      </div>
                    </>
                  )}
                </div>
                <div className={style.resp_action}>
                  <button type="button" onClick={copyAPIResponse}>
                    <i className="feather-copy"></i>
                  </button>
                  <button disabled>
                    <i className="feather-search"></i>
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className={style.scroll}>
            {(() => {
              switch (respView) {
                case "body":
                  return (
                    <ResponseBody
                      data={state.tabs[state.activeTabId - 1]?.apiResponse.data}
                      wrap={wordWrap}
                      viewAs={viewAs}
                      viewMode={viewMode}
                    />
                  );
                case "headers":
                  return (
                    <HeadersTable
                      headers={Object.entries(
                        state.tabs[state.activeTabId - 1]?.apiResponse.headers
                      )}
                    />
                  );
                default:
                  return;
              }
            })()}
          </div>
        </div>
      ) : (
        <div>
          {state.tabs[state.activeTabId - 1]?.apiError && (
            <div className={style.apiError}>
              {state.tabs[state.activeTabId - 1]?.apiError}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ResponseWrapper;