/* eslint-disable react/prop-types */
import React, { useState } from "react";
import styles from "./CustomDropdown.module.css";
import { useEffect } from "react";

const CustomDropdown = ({
  type,
  setType,
  label,
  options,
  onSelect,
  header,
  disabled,
  whichDropdown,
  value = "", // Add value prop to control from parent
}) => {
  const [isOpen, setIsOpen] = useState(false);
  // Remove internal selectedValue state, use value from props instead

  // Sort options alphabetically by name
  const sortedOptions = () => {
    if (!options || options.length === 0) return [];

    // If there's only one option, no need to sort
    if (options.length === 1) return options;

    // Extract the last item
    const lastItem = options[options.length - 1];

    // Sort all items except the last one
    const itemsToSort = options.slice(0, options.length - 1);
    const sortedItems = [...itemsToSort].sort((a, b) => a.name.localeCompare(b.name));

    // Append the last item to the sorted array
    return [...sortedItems, lastItem];
  };

  const handleItemClick = (value) => {
    setIsOpen(false);
    if (onSelect) onSelect(value);
    setType(parseInt(whichDropdown).toString());
  };

  useEffect(() => {
    if (type === "1") {
      if (whichDropdown === "2" || whichDropdown === "3") {
        setIsOpen(false);
      }
    } else if (type === "2") {
      if (whichDropdown === "3") {
        setIsOpen(false);
      }
    }
  }, [type, options, whichDropdown]);

  return (
    <div className={styles.dropdown}>
      <label className={styles.label}>{label}</label>
      <div
        className={`${styles.dropdownHeader} ${disabled ? styles.disabled : ""}`}
        onClick={() => !disabled && setIsOpen(!isOpen)} // Prevent opening if disabled
      >
        {value || label}
        <svg
          className={styles.arrow}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path d={isOpen ? "M7 14l5-5 5 5" : "M7 10l5 5 5-5"} />
        </svg>
      </div>
      <ul className={`${styles.dropdownList} ${isOpen ? null : styles.hidden}`}>
        {isOpen && header && (
          <div className={styles.dropdownHeaderText}>{header}</div>
        )}
        {sortedOptions()?.map((option, index) => (
          <li
            key={index}
            className={styles.dropdownItem}
            onClick={() => handleItemClick(option)}
          >
            {option.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CustomDropdown;
