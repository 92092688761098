import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom"; // For safer URL handling
import HeatMap from "./HeatMap/HeatMap";
import LineChart from "./LineChart/LineChart";
import styles from "./Analytics.module.css";
import api from "../Api";
import WarningModal from "../WarningModal/WarningModal";
import Loader from "../Loader/Loader.js";

const Analytics = ({ categories }) => {
  const [leftSelectedFirst, setLeftSelectedFirst] = useState("Completeness");
  const [leftSelectedSecond, setLeftSelectedSecond] = useState("Environment");
  const [rightSelected, setRightSelected] = useState("Completeness");
  const [templates, setTemplates] = useState(null);
  const location = useLocation(); // Use react-router-dom for safer URL handling
  const userID = new URLSearchParams(location.search).get("userID");
  const [catSelected, setCatSelected] = useState(null);
  const [tempSelected, setTempSelected] = useState(null);
  const [showWarning, setShowWarning] = useState(false);
  const [message, setMessage] = useState("");
  const [catCol, setCatCol] = useState(null);
  const [filteredTemplates, setFilteredTemplates] = useState(null);
  const [tempCol, setTempCol] = useState(null);
  const [mandatoryFieldsData, setMandatoryFieldsData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // const chartData = {
  //   monthly: {
  //     labels: [
  //       "Jan",
  //       "Feb",
  //       "Mar",
  //       "Apr",
  //       "May",
  //       "Jun",
  //       "Jul",
  //       "Aug",
  //       "Sep",
  //       "Oct",
  //       "Nov",
  //       "Dec",
  //     ],
  //     data: [65, 59, 80, 81, 56, 55, 40, 70, 60, 45, 65, 78],
  //   },
  //   quarterly: {
  //     labels: ["Q1", "Q2", "Q3", "Q4"],
  //     data: [70, 60, 80, 75],
  //   },
  //   yearly: {
  //     labels: ["2019", "2020", "2021", "2022", "2023"],
  //     data: [65, 70, 80, 75, 90],
  //   },
  // };

  const durationOptions = [
    { value: "monthly", label: "Monthly" },
    { value: "quarterly", label: "Quarterly" },
    { value: "yearly", label: "Yearly" },
  ];

  useEffect(() => {
    if (categories?.length) {
      setCatSelected(null); // Reset category selection
      setCatCol(
        categories.reduce((acc, cat) => {
          acc[cat.category_id] = styles.red;
          return acc;
        }, {}),
      );
    }
  }, [categories]);

  useEffect(() => {
    setFilteredTemplates(
      templates?.filter((temp) =>
        categories?.some(
          (cat) =>
            cat.pillars === leftSelectedSecond && cat.name === temp.category,
        ),
      ),
    );
  }, [leftSelectedSecond, templates, categories]);

  const fetchUserTemplates = async () => {
    try {
      const response = await api.get(`/getAllTemplates`);
      const transformedData = response.data.map((template) => ({
        id: template.template_id,
        name: template.name,
        category: template.category,
      }));
      setTemplates(transformedData);
      setTempCol(
        transformedData?.reduce((acc, cat) => {
          acc[cat.id] = styles.red;
          return acc;
        }, {}),
      );
    } catch (error) {
      console.error("Error fetching templates:", error);
    }
  };

  const fetchCatCompleteness = async () => {
    if (!categories) return; // Prevent fetching if categories are not available

    try {
      const res = await api.post("/getCategoriesStatus", { userID });
      // console.log(res.data);

      setCatCol(
        res.data.categories.reduce((acc, cat) => {
          const comp = Number(cat.completeness); // No need for parseInt unless data is a string
          // console.log(cat.category, comp, comp === 100);

          if (comp === 0) acc[cat.id] = styles.red;
          else if (comp > 0 && comp <= 25) acc[cat.id] = styles.lightyellow;
          else if (comp > 25 && comp <= 50) acc[cat.id] = styles.darkyellow;
          else if (comp > 50 && comp <= 75) acc[cat.id] = styles.lightgreen;
          else if (comp > 75 && comp <= 99) acc[cat.id] = styles.green;
          else acc[cat.id] = styles.darkgreen;
          return acc;
        }, {}),
      );
    } catch (err) {
      console.error("Error fetching category completeness:", err);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchCatCompleteness().then(() => setIsLoading(false));
  }, [categories]);

  const fetchTempCompleteness = async () => {
    if (!templates || !templates.length) return; // Prevent fetching if categories are not available
    // console.log(filteredTemplates);

    try {
      const res = await api.post("/getTemplatesStatus", {
        userID,
        templates: templates,
      });
      // console.log(res.data);

      setTempCol(
        res.data.templates.reduce((acc, temp) => {
          const comp = Number(temp.completeness); // No need for parseInt unless data is a string
          // console.log(temp);

          if (comp === 0) acc[temp.id] = styles.red;
          else if (comp > 0 && comp <= 25) acc[temp.id] = styles.lightyellow;
          else if (comp > 25 && comp <= 50) acc[temp.id] = styles.darkyellow;
          else if (comp > 50 && comp <= 75) acc[temp.id] = styles.lightgreen;
          else if (comp > 75 && comp <= 99) acc[temp.id] = styles.green;
          else acc[temp.id] = styles.darkgreen;
          return acc;
        }, {}),
      );
    } catch (err) {
      console.error("Error fetching category completeness:", err);
    }
  };

  useEffect(() => {
    setTempCol(
      templates?.reduce((acc, cat) => {
        acc[cat.id] = styles.red;
        return acc;
      }, {}),
    );
    fetchTempCompleteness();
  }, [templates]);

  useEffect(() => {
    if (userID) {
      fetchUserTemplates();
    }
  }, [userID]);

  useEffect(() => {
    if (templates && catSelected) {
      setTempSelected(null);
    }
  }, [catSelected, templates]);

  const fetchAllMandatoryFields = async () => {
    setIsLoading(true);
    try {
      if (!tempSelected) return;
      const res = await api.post("/fetchAllMandatoryFields", {
        userID,
        templateId: tempSelected.id,
      });

      const responseData = res?.data?.mandatoryFieldsData;

      if (responseData) {
        const formattedData = Object.keys(responseData).map((fieldName) => {
          const monthlyData = responseData[fieldName];

          // Process the data to create quarterly and yearly views
          const { quarterlyData, yearlyData } =
            processDataByTimeframes(monthlyData);

          return {
            name: fieldName,
            labels: monthlyData.labels,
            data: monthlyData.data,
            quarterly: quarterlyData,
            yearly: yearlyData,
          };
        });
        console.log(formattedData);
        setMandatoryFieldsData(formattedData);
      } else {
        console.warn("No mandatory fields data returned from API");
      }
    } catch (err) {
      setShowWarning(true);
      setMessage(err?.response?.data?.message || "An error occurred");
      console.error(err);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setMandatoryFieldsData(null);
    fetchAllMandatoryFields();
  }, [tempSelected]);

  const processDataByTimeframes = (monthlyData) => {
    const quarterlyLabels = [];
    const quarterlyData = [];
    const yearlyLabels = [];
    const yearlyData = [];

    // Group data by quarter
    const quarterMap = new Map();
    const yearMap = new Map();

    monthlyData.labels.forEach((dateStr, index) => {
      // Parse the date (format: DD/MM/YYYY)
      const parts = dateStr.split("/");
      const month = parseInt(parts[1], 10);
      const year = parts[2];

      // Determine quarter
      const quarter = Math.ceil(month / 3);
      const quarterKey = `Q${quarter} ${year}`;
      const yearKey = year;

      // Add to quarter map
      if (!quarterMap.has(quarterKey)) {
        quarterMap.set(quarterKey, []);
      }
      quarterMap.get(quarterKey).push(monthlyData.data[index]);

      // Add to year map
      if (!yearMap.has(yearKey)) {
        yearMap.set(yearKey, []);
      }
      yearMap.get(yearKey).push(monthlyData.data[index]);
    });

    // Calculate averages or sums for quarters
    quarterMap.forEach((values, quarterKey) => {
      quarterlyLabels.push(quarterKey);
      // You can choose to use sum or average based on what makes sense for your data
      quarterlyData.push(values.reduce((sum, value) => sum + value, 0));
    });

    // Calculate averages or sums for years
    yearMap.forEach((values, yearKey) => {
      yearlyLabels.push(yearKey);
      // You can choose to use sum or average based on what makes sense for your data
      yearlyData.push(values.reduce((sum, value) => sum + value, 0));
    });

    return {
      quarterlyData: {
        labels: quarterlyLabels,
        data: quarterlyData,
      },
      yearlyData: {
        labels: yearlyLabels,
        data: yearlyData,
      },
    };
  };

  if (isLoading) {
    return <Loader />;
  }
  return (
    <div className={styles.container}>
      <HeatMap
        info={categories?.map((cat) => ({
          id: cat.category_id,
          name: cat.name,
          category: cat.pillars,
        }))}
        categorizeInto={["Environment", "Social", "Governance"]}
        leftDropdownOptions={[{ value: "Completeness", label: "Completeness" }]}
        leftSelected={leftSelectedFirst}
        setLeftSelected={setLeftSelectedFirst}
        colorMap={catCol}
      />

      <HeatMap
        info={filteredTemplates}
        categorizeInto={categories
          ?.filter((cat) => cat.pillars === leftSelectedSecond)
          .map((cat) => cat.name)}
        leftDropdownOptions={[
          { value: "Environment", label: "Environment" },
          { value: "Social", label: "Social" },
          { value: "Governance", label: "Governance" },
        ]}
        leftSelected={leftSelectedSecond}
        setLeftSelected={setLeftSelectedSecond}
        rightDropdownOptions={[
          { value: "Completeness", label: "Completeness" },
        ]}
        rightSelected={rightSelected}
        setRightSelected={setRightSelected}
        colorMap={tempCol}
      />

      <div className={styles.dropdownsContainer}>
        <select
          className={styles.leftDropdown}
          value={catSelected?.name || ""}
          onChange={(e) =>
            setCatSelected(
              categories.find((cat) => cat.name === e.target.value) || null,
            )
          }
        >
          <option value="" disabled>
            Select Category
          </option>
          {categories?.map((cat) => (
            <option key={cat.category_id} value={cat.name}>
              {cat.name}
            </option>
          ))}
        </select>

        <select
          className={styles.rightDropdown}
          value={tempSelected?.name || ""}
          onChange={(e) =>
            setTempSelected(
              templates.find((temp) => temp.name === e.target.value) || null,
            )
          }
          disabled={!catSelected}
        >
          <option value="" disabled>
            Select Template
          </option>
          {templates
            ?.filter((temp) => temp.category === catSelected?.name)
            .map((temp) => (
              <option key={temp.id} value={temp.name}>
                {temp.name}
              </option>
            ))}
        </select>
      </div>

      {showWarning && (
        <WarningModal
          icon={
            <i
              className="bi bi-exclamation-triangle-fill"
              style={{ color: "#D6746F" }}
            ></i>
          }
          message={message}
          okButton="OK"
          onSave={() => setShowWarning(false)}
        />
      )}
      <div className={styles.charts}>
        {mandatoryFieldsData?.map((field, index) => (
          <LineChart
            key={index}
            parameter={field.name} // Pass the field name as parameter
            chartData={{
              // Convert to the format expected by LineChart
              monthly: {
                labels: field.labels,
                data: field.data,
              },
              // You could add quarterly and yearly views if needed by aggregating the data
            }}
            durationOptions={durationOptions}
          />
        ))}
      </div>
    </div>
  );
};

export default Analytics;
