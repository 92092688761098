import React from "react";
import styles from "./SaveMapping.module.css";

const SaveMapping = ({
  name,
  setName,
  onCancel,
  onSave,
  title,
  errMessage,
  showOverWrite = false,
  onOverWrite,
}) => {
  return (
    <div className={styles.formWrap}>
      <div className={styles.formContainer}>
        <div className={styles.name}>
          <label>{title}</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className={styles.inputField}
          />
          <p style={{ color: "#D6746F" }}>{errMessage}</p>
        </div>
        <div className={styles.buttonGroup}>
          <button onClick={onCancel} className={styles.cancelButton}>
            Cancel
          </button>
          {showOverWrite ? (
            <button
              onClick={onOverWrite}
              disabled={!name}
              className={styles.saveButton}
            >
              Overwrite
            </button>
          ) : null}
          <button
            onClick={onSave}
            disabled={!name}
            className={styles.saveButton}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.58984 16.58L13.1698 12L8.58984 7.41L9.99984 6L15.9998 12L9.99984 18L8.58984 16.58Z"
                fill="#F7F7F8"
              />
            </svg>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};
export default SaveMapping;
