import React from "react";
import styles from "./HeatMap.module.css";

const Heatmap = ({
  info,
  categorizeInto,
  leftDropdownOptions = null,
  rightDropdownOptions = null,
  leftSelected,
  setLeftSelected,
  rightSelected,
  setRightSelected,
  colorMap,
}) => {
  // console.log(colorMap);
  const categorize = () => {

    if (!categorizeInto || !Array.isArray(categorizeInto)) return [];
    if (!info || !Array.isArray(info)) return [];
    // console.log(info);
    const groupedData = {};

    // Initialize all categories with empty arrays
    const temp = categorizeInto?.forEach((category) => {
      groupedData[category] = [];
    });

    // Populate categories with data from `info`
    const temp2 = info?.forEach(({ id, name, category }) => {
      if (!category) return;
      if (!groupedData[category]) {
        groupedData[category] = [];
      }
      groupedData[category].push({ id, name });
    });

    // Ensure each category has at least 5 rows, filling empty values as needed
    Object.keys(groupedData).forEach((key) => {
      while (groupedData[key].length < 5) {
        groupedData[key].push({ name: "" });
      }
    });

    const maxLength = Math.max(
      ...Object.values(groupedData).map((arr) => arr.length),
      0,
    );

    Object.keys(groupedData).forEach((key) => {
      while (groupedData[key].length < maxLength) {
        groupedData[key].push({ name: "" });
      }
    });

    return Object.entries(groupedData).map(([title, values]) => ({
      title,
      values,
    }));
  };

  const data = categorize();
  // console.log("data: ", data);

  return (
    <div className={styles.heatmapWrapper}>
      <div className={styles.dropdownsContainer}>
        <select
          className={styles.leftDropdown}
          value={leftSelected}
          onChange={(e) => setLeftSelected(e.target.value)}
        >
          {leftDropdownOptions?.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        {rightDropdownOptions && (
          <select
            className={styles.rightDropdown}
            value={rightSelected}
            onChange={(e) => setRightSelected(e.target.value)}
          >
            {rightDropdownOptions?.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        )}
      </div>
      <div className={styles.heatmapContainer}>
        <div className={styles.fixedColumn}>
          {categorizeInto?.map((row, rowIndex) => (
            <div key={rowIndex} className={styles.heatmapTitle}>
              {categorizeInto[rowIndex]}
            </div>
          ))}
        </div>
        <div className={styles.scrollableContent}>
          <table className={styles.heatmapTable}>
            <tbody>
              {data?.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row?.values?.map((val, colIndex) => (
                    <td
                      key={colIndex}
                      className={`${styles.heatmapCell} ${!val || !val.name || val.name.trim() === ""
                          ? styles.empty
                          : colorMap
                            ? colorMap[val.id]
                            : null
                        }`}
                    >
                      {val.name}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className={styles.heatmapLegend}>
        <span className={`${styles.legendItem} ${styles.red}`}>0%</span>
        <span className={`${styles.legendItem} ${styles.lightyellow}`}>
          1-25%
        </span>
        <span className={`${styles.legendItem} ${styles.darkyellow}`}>
          25-50%
        </span>
        <span className={`${styles.legendItem} ${styles.lightgreen}`}>
          50-75%
        </span>
        <span className={`${styles.legendItem} ${styles.green}`}>
          75-99%
        </span>
        <span className={`${styles.legendItem} ${styles.darkgreen}`}>
          100%
        </span>
      </div>
    </div>
  );
};

export default Heatmap;
