import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import styles from "./LineChart.module.css";

// No need to register chart elements in v2 - it's done automatically

const LineChart = ({ parameter, chartData, durationOptions }) => {
  // Initialize with the first duration option's value instead of empty string
  const [selectedDuration, setSelectedDuration] = useState(durationOptions[0]?.value || "");

  // Make sure we have a valid selection, defaulting to the first option if none is selected
  const activeDuration = selectedDuration || durationOptions[0]?.value;
  
  // Ensure we have valid data for the selected duration
  const currentData = chartData[activeDuration] || { labels: [], data: [] };

  const data = {
    // These are x-axis labels
    labels: currentData.labels,
    datasets: [
      {
        label: `${parameter} Value`,
        // These are y-axis values that correspond to each x-axis label
        data: currentData.data,
        fill: false,
        backgroundColor: "rgb(44, 175, 254)",
        borderColor: "rgb(44, 175, 254)",
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      // Chart.js v2 uses xAxes and yAxes arrays instead of x and y objects
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Time'
        }
      }],
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: parameter
        }
      }]
    },
    legend: {
      position: "top",
    },
    tooltips: {
      callbacks: {
        // Format the tooltip to show both x and y values clearly
        label: function(tooltipItem, data) {
          return `${parameter}: ${tooltipItem.yLabel}`;
        }
      }
    },
  };

  return (
    <div className={styles.chartContainer}>
      <div className={styles.header}>
        <div className={styles.title}>{parameter}</div>
        <select
          id="durationSelect"
          className={styles.select}
          value={selectedDuration}
          onChange={(e) => setSelectedDuration(e.target.value)}
        >
          <option value="" disabled>
            Duration
          </option>
          {durationOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      <div className={styles.chartWrapper}>
        <Line data={data} options={options} />
      </div>
    </div>
  );
};

export default LineChart;