/* eslint-disable react/prop-types */
import React from "react";
import styles from './UploadSuccess.module.css'; // Adjust the path to your CSS module

const UploadSuccess = ({ message }) => {
  return (
    <div className={styles.overlay}>
      <div className={styles.successContainer}>
        <div className={styles.successIcon}>
          <i className="bi bi-check-circle-fill"></i> {/* Bootstrap icon for checkmark */}
        </div>
        <h2 className={styles.successMessage}>
          {message || "Template uploaded successfully"}
        </h2>
      </div>
    </div>
  );
};

export default UploadSuccess;