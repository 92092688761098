/* eslint-disable react/prop-types */
import React from "react";
import styles from "../ShowDataTable.module.css";
import TableCell from "./TableCell";
import ActionDropdown from "../ActionDropDown/ActionDropdown";
import { useLocation, useHistory } from "react-router-dom";

const TableRow = ({
  row = [],
  rowIndex,
  handleRowEdit,
  handleClose,
  handleDropdownClick,
  handleViewAndManageTemplate,
  handleRemoveRow,
  editingCell = {},
  dropdownState = {},
  showCheckBox = false,
  shouldSlice = true,
  sectionType,
  fileInfo,
}) => {
  const navigate = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userID = queryParams.get("userID");
  const section = queryParams.get("section");
  const isAdmin = localStorage.getItem("UserType") == 1;
  const rowData = shouldSlice ? row?.slice(1) : row;
  return (
    <>
      {/* Row Index */}
      <td className={styles.tableCell}>
        <span>{rowIndex + 1}</span>
      </td>

      {/* Table Cells */}

      {rowData?.map((cell, cellIndex) => (
        <td
          key={cellIndex}
          className={`${styles.tableCell} ${styles.tableCellData}`}
        >
          {cell !== "Actions" ? (
            <TableCell
              value={cell}
              isEditing={
                editingCell.rowIndex === rowIndex + 1 &&
                editingCell.cellIndex === cellIndex
              }
              onClick={(e) => {
                e.stopPropagation(); // Stop event bubbling
                if(sectionType==="Data File"){
                  if (cellIndex === 0&&row[3].toLowerCase()!=="in progress") {
                    navigate.push(`/data-management/viewData?userID=${userID}&fileId=${row[0]}`);
                  }
                }
                else if(sectionType==="API"){
                  navigate.push(`/data-management/createRequest?userID=${userID}&fileId=${row[0]}&section=${section}&api=${row[0][1]}`);
                }
                else if (sectionType === "Template") {
                  navigate.push(`/data-management/manageTemplate?userID=${userID}&manageTemplateID=${row[0]}&section=${section}`);
                }
              }}
            />
          ) : (
            <div className={styles.dropdowncontainer}>
              {/* Edit Button with SVG Icon */}
              {sectionType !== "Data File" &&
              sectionType !== "API" &&
              isAdmin ? (
                <button
                  className={`${styles.button} ${styles.noPadding}`}
                  onClick={() => handleRowEdit(rowIndex)}
                >
                  <svg
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    width="35"
                    height="16"
                  >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M12.9 6.858l4.242 4.243L7.242 21H3v-4.243l9.9-9.9zm1.414-1.414l2.121-2.122a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414l-2.122 2.121-4.242-4.242z"></path>
                  </svg>
                </button>
              ) : null}

              {/* Dropdown Button */}
              <button
                className={styles.button}
                style={{
                  paddingLeft: `${sectionType === "Data File" ? "2rem !important" : null}`,
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleDropdownClick(rowIndex + 1, row.length, e);
                }}
              >
                <svg
                  width="32"
                  viewBox="0 0 16 4"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="2" cy="2" r="2" fill="#786ACD" />
                  <circle cx="8" cy="2" r="2" fill="#786ACD" />
                  <path
                    d="M16 2C16 3.10457 15.1046 4 14 4C12.8954 4 12 3.10457 12 2C12 0.89543 12.8954 0 14 0C15.1046 0 16 0.89543 16 2Z"
                    fill="#786ACD"
                  />
                </svg>
              </button>

              {dropdownState?.showOptions?.rowIndex === rowIndex + 1 &&
                dropdownState?.showOptions?.cellIndex === row.length && (
                  <ActionDropdown
                    id={row[0]}
                    row={row}
                    route={fileInfo ? fileInfo.find(file => file.file_id === row[0])?.route_path : null}
                    sectionType={sectionType}
                    handleClose={handleClose}
                    position={dropdownState?.position}
                    onViewManageTemplate={handleViewAndManageTemplate}
                    handleRemoveRow={() => handleRemoveRow(row[0])}
                  />
                )}
            </div>
          )}
        </td>
      ))}
    </>
  );
};

export default TableRow;
