import React, { useState } from "react";
import { FaChevronRight, FaChevronDown } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./SearchFilterBar.module.css";

const SearchFilterBar = ({
  setSearchText,
  setFilterCriteria,
  categories,
  statusOptions,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [openOption, setOpenOption] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [lastUpdatedByDate, setLastUpdatedByDate] = useState(null);
  const [lastUpdatedOnDate, setLastUpdatedOnDate] = useState(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const toggleOption = (option) => {
    setOpenOption(openOption === option ? null : option);
  };

  const handleCategoryChange = (category) => {
    setSelectedCategories((prev) =>
      prev.includes(category)
        ? prev.filter((item) => item !== category)
        : [...prev, category],
    );
  };

  const handleStatusChange = (status) => {
    setSelectedStatus((prev) =>
      prev.includes(status)
        ? prev.filter((item) => item !== status)
        : [...prev, status],
    );
  };

  const handleApply = () => {
    // Pass the filter criteria to the parent component
    setFilterCriteria({
      categories: selectedCategories,
      status: selectedStatus,
      lastUpdatedBy: lastUpdatedByDate,
      lastUpdatedOn: lastUpdatedOnDate,
    });
    setIsOpen(false);
  };

  const handleCancel = () => {
    setSelectedCategories([]);
    setSelectedStatus([]);
    setLastUpdatedByDate(null);
    setLastUpdatedOnDate(null);
    setFilterCriteria({
      categories: [],
      status: [],
      lastUpdatedBy: null,
      lastUpdatedOn: null,
    });
  };

  return (
    <div className={styles.container}>
      <input
        type="search"
        placeholder="Search title"
        onChange={(e) => setSearchText(e.target.value)}
        className={styles.searchInput}
      />

      <div className={styles.filterWrapper}>
        <div onClick={toggleDropdown} className={styles.filterBar}>
          Filter By
          <FaChevronDown />
        </div>

        {isOpen && (
          <div className={styles.dropdown} onClick={(e) => e.stopPropagation()}>
            {/* Category Option */}
            <div className={styles.option}>
              <div
                onClick={() => toggleOption("category")}
                className={styles.optionHeader}
              >
                <span>Category</span>
                {openOption === "category" ? (
                  <FaChevronDown />
                ) : (
                  <FaChevronRight />
                )}
              </div>
              {openOption === "category" && (
                <div className={styles.optionContent}>
                  {categories.map((category) => (
                    <div key={category} className={styles.checkboxItem}>
                      <label>
                        <input
                          type="checkbox"
                          checked={selectedCategories.includes(category)}
                          onChange={() => handleCategoryChange(category)}
                          className={styles.checkbox}
                        />
                        <span>{category}</span>
                      </label>
                    </div>
                  ))}
                </div>
              )}
            </div>

            {/* Status Option */}
            <div className={styles.option}>
              <div
                onClick={() => toggleOption("status")}
                className={styles.optionHeader}
              >
                <span>Status</span>
                {openOption === "status" ? (
                  <FaChevronDown />
                ) : (
                  <FaChevronRight />
                )}
              </div>
              {openOption === "status" && (
                <div className={styles.optionContent}>
                  {statusOptions.map((status) => (
                    <div key={status} className={styles.checkboxItem}>
                      <label>
                        <input
                          type="checkbox"
                          checked={selectedStatus.includes(status)}
                          onChange={() => handleStatusChange(status)}
                          className={styles.checkbox}
                        />
                        <span>{status}</span>
                      </label>
                    </div>
                  ))}
                </div>
              )}
            </div>

            {/* Last Updated By Option */}
            <div className={styles.option}>
              <div
                onClick={() => toggleOption("lastUpdatedBy")}
                className={styles.optionHeader}
              >
                <span>Last Updated By</span>
                {openOption === "lastUpdatedBy" ? (
                  <FaChevronDown />
                ) : (
                  <FaChevronRight />
                )}
              </div>
              {openOption === "lastUpdatedBy" && (
                <div className={styles.optionContent}>
                  <DatePicker
                    selected={lastUpdatedByDate}
                    onChange={(date) => setLastUpdatedByDate(date)}
                    className={styles.datePicker}
                  />
                </div>
              )}
            </div>

            {/* Last Updated On Option */}
            <div className={styles.option}>
              <div
                onClick={() => toggleOption("lastUpdatedOn")}
                className={styles.optionHeader}
              >
                <span>Last Updated On</span>
                {openOption === "lastUpdatedOn" ? (
                  <FaChevronDown />
                ) : (
                  <FaChevronRight />
                )}
              </div>
              {openOption === "lastUpdatedOn" && (
                <div className={styles.optionContent}>
                  <DatePicker
                    selected={lastUpdatedOnDate}
                    onChange={(date) => setLastUpdatedOnDate(date)}
                    className={styles.datePicker}
                  />
                </div>
              )}
            </div>

            {/* Buttons */}
            <div className={styles.buttons}>
              <button onClick={handleCancel} className={styles.cancelButton}>
                Clear All
              </button>
              <button onClick={handleApply} className={styles.applyButton}>
                Apply
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchFilterBar;
