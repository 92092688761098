import React from "react";
/* eslint-disable react/prop-types */
import styles from "./SaveCancelButton.module.css";
const SaveCancelButton = ({
  isMappingScreen,
  handleSave,
  handleFinish,
  message,
  handleCancel
}) => {

  return (
    <div className={styles.actions}>
      <button
        className={`${styles.cancelButton} ${styles.cancelandSave}`}
        onClick={handleCancel}
      >
        <i className="bi bi-x-lg"></i> Cancel
      </button>
      <div className={styles.saveCreateButton}>
        {isMappingScreen ? (
          <button
            className={`${styles.saveButton} ${styles.cancelandSave}`}
            onClick={handleSave}
          >
            <i className="bi bi-floppy2-fill"></i>Save
          </button>
        ) : null}
        <button className={styles.createButton} onClick={handleFinish}>
          <i className="bi bi-grid-3x3-gap-fill"></i>
          {message}
        </button>
      </div>
    </div>
  );
};

export default SaveCancelButton;
