import React from "react";
import { useContext } from 'react';
import { Context } from '../contexts/Store';
import styles from './playground.module.css';
import ResponseWrapper from './response/ResponseWrapper';
import image from './hitsend.svg';

const ResponseViewer = () => {
  const { state, dispatch } = useContext(Context);


  const toggleView = () => {
    dispatch({
      type: 'SET_RESPONSE_PANEL',
      payload: !state.tabs[state.activeTabId - 1]?.responsePanelMinimized,
    });
  };

  return (
    <div
      className={
        state.tabs[state.activeTabId - 1]?.responsePanelMinimized
          ? styles.response_panel_small
          : styles.response_panel__blank
      }
    >
      {state.tabs[state.activeTabId - 1]?.responseUI ? (
        <ResponseWrapper />
      ) : (
        <>
          <div className={styles.response_toggle_btn}>
            <span>Response</span>
            {state.splitView === 'H' && (
              <span
                onClick={toggleView}
                className={
                  state.tabs[state.activeTabId - 1]?.responsePanelMinimized
                    ? styles.toggle_arrow
                    : styles.toggle_arrow_up
                }
              >
                ▼
              </span>
            )}
          </div>
          {!state.tabs[state.activeTabId - 1]?.responsePanelMinimized && (
            <div className={styles.response_ph}>
              <img src={image} alt="" />
              <span>Enter the URL and click Send to get a response.</span>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ResponseViewer;
