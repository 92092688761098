import React from "react";

const TableCell = ({ value, onClick }) => {
  const normalisedValue = value.toLowerCase();

  const statusSVG = (status) => {
    switch (status) {
      case "complete":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="38"
            viewBox="0 0 100 100"
          >
            <circle
              cx="50"
              cy="50"
              r="45"
              fill="none"
              stroke="green"
              strokeWidth="10"
            />
            <path
              d="M30 50 L45 65 L70 35"
              fill="none"
              stroke="green"
              strokeWidth="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case "in progress":
        return (
          <svg
            width="38"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.9998 0.03V0.05V2.05C15.3898 2.59 18.4998 6.58 17.9598 10.97C17.4998 14.61 14.6398 17.5 10.9998 17.93V19.93C16.4998 19.38 20.4998 14.5 19.9498 9C19.4998 4.25 15.7298 0.5 10.9998 0.03ZM8.9998 0.06C7.0498 0.25 5.18981 1 3.6698 2.26L5.0998 3.74C6.2198 2.84 7.5698 2.26 8.9998 2.06V0.06ZM2.2598 3.67C0.999805 5.19 0.249805 7.04 0.0498047 9H2.0498C2.2398 7.58 2.7998 6.23 3.6898 5.1L2.2598 3.67ZM13.4998 6.5L8.6198 11.38L6.4998 9.26L5.43981 10.32L8.6198 13.5L14.5598 7.56L13.4998 6.5ZM0.0598047 11C0.259805 12.96 1.0298 14.81 2.2698 16.33L3.6898 14.9C2.8098 13.77 2.2398 12.42 2.0598 11H0.0598047ZM5.0998 16.37L3.6698 17.74C5.1798 19 7.0398 19.79 8.9998 20V18C7.5798 17.82 6.2298 17.25 5.0998 16.37Z"
              fill="#1232C3"
            />
          </svg>
        );
      default:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="38"
            viewBox="0 0 100 100"
          >
            <circle
              cx="50"
              cy="50"
              r="45"
              fill="none"
              stroke="red"
              strokeWidth="10"
            />
            <path
              d="M35 35 L65 65 M65 35 L35 65"
              fill="none"
              stroke="red"
              strokeWidth="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
    }
  };

  return ["complete", "in progress", "something went wrong"].includes(
    normalisedValue,
  ) ? (
    statusSVG(normalisedValue)
  ) : (
    <span
      style={{
        ...(value === "Active"
          ? { color: "green" }
          : value === "Inactive"
            ? { color: "red" }
            : {}),
        cursor: onClick ? "pointer" : "default",
      }}
      onClick={onClick}
    >
      {value}
    </span>
  );
};

export default TableCell;
