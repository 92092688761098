import React from "react";
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { useCallback, useEffect, useState,useMemo } from "react";
import styles from "./ShowDataTable.module.css";
import TableRow from "./TableRow/TableRow";
import TableHeader from "./TableHeader/TableHeader";
import WarningModal from "../WarningModal/WarningModal";
import SaveCancelButton from "../SaveCancelButton/SaveCancelButton";
import SearchFilter from "../SearchFilterBar/SearchFilterBar";
import ProgressBar from "./ProgressBar/ProgressBar";
import { useLocation, useHistory } from "react-router-dom";
import api from "../Api";
import UploadSuccess from "../UploadSuccess/UploadSuccess";
import SaveMapping from "./SaveMapping/SaveMapping";
import Loader from "../Loader/Loader.js";

const ShowDataTable = ({
  isHomeSection = false,
  showUnits = false,
  showLoader = true,
  setIsLoading,
  isDataFileUploadSection = false,
  showBackButton = false,
  showMappingTip = false,
  showMatching,
  showSorting,
  isMappingScreen = false,
  extraInfo,
  fileInfo,
  showSaveCancelButton = false,
  currentStep = null,
  showTitle,
  extractedData,
  headerData,
  isTemplateSection,
  setSelectedTemplateId,
  onDelete,
  ApplyClass,
  showSearchFilter = false,
  showCheckBox = true,
  shouldSlice = true,
  data,
  unmatchedFileColumns,
  setFileInfo,
  message,
  defaultMap = null,
  setDefaultMap,
  sectionType,
  categories,
  fileUnits={}
}) => {
  // console.log("headerData: ", headerData);
  // console.log("applyclass: ", ApplyClass);
  // console.log("extractedData: ", extractedData);
  // console.log(fileInfo);
  //isFileUploaded -> 1 for without template, 2 for with template
  const navigate = useHistory();
  const handleAddClass = useCallback(
    (index) => {
      if (ApplyClass) {
        if (ApplyClass[index] === -2) return styles.whiteBg;
        if (ApplyClass[index] === 0) return styles.blackBg;
        if (ApplyClass[index] === 1) return styles.greenBg;
        if (ApplyClass[index] === -1) return styles.redBg;
        if (ApplyClass[index] === 2) return styles.greyBg;
      }
    },
    [ApplyClass],
  );

  const [filterCriteria, setFilterCriteria] = useState({
    categories: [],
    status: [],
    lastUpdatedBy: null,
    lastUpdatedOn: null,
  });
  const location = useLocation();

  const [sortColumn, setSortColumn] = useState(1);
  const [showMandatoryWarning, setShowMandatoryWarning] = useState(false);
  const [showOptionalWarning, setShowOptionalWarning] = useState(false);
  const [showSaveMapping, setShowSaveMapping] = useState(false);
  const [name, setName] = useState("");
  const [errMessage, setErrMessage] = useState(null);
  const [showSaved, setShowSaved] = useState(false);
  const [isSavedClick, setIsSaveClick] = useState(false);
  const [saveMessage, setSaveMessage] = useState(null);
  const [showFinalize, setShowFinalize] = useState(false);
  const [showFileInfo, setShowFileInfo] = useState(false);
  const [sortOption, setSortOption] = useState("asc");
  const [showUploadEvidencePopUp, setShowUploadEvidencePopUp] = useState(false);
  const [showUnsavedWarning, setShowUnsavedWarning] = useState(false);
  const [option, setOption] = useState("");
  const queryParams = new URLSearchParams(location.search);
  const fileId = parseInt(queryParams.get("fileId") || null);
  const userID = parseInt(queryParams.get("userID") || null);

  const [internalLoading, setInternalLoading] = useState(false);
  const [deleteConfirmationState, setDeleteConfirmationState] = useState({
    showModal: false,
    idToDelete: null
  });

  // Create a loading state based on showLoader prop
  const loadingState = useMemo(() => {
    if (showLoader) {
      return { isLoading: internalLoading, setIsLoading: setInternalLoading };
    }

    // Check if setIsLoading is a function before returning it
    return {
      isLoading: false,
      setIsLoading:
        typeof setIsLoading === "function" ? setIsLoading : () => {},
    };
  }, [showLoader, setIsLoading, internalLoading]);

  const [state, setState] = useState({
    selectedOptions: {},
    tableTitle: extraInfo?.fileName,
    headerData: headerData || [],
    showWarning: false,
  });

  const [searchText, setSearchText] = useState(null);
  const [filteredExtractedData, setFilteredExtractedData] = useState(
    extractedData || [],
  );

  const sortData = (data) => {
    const sortingInd = showCheckBox ? sortColumn : sortColumn - 1;
    const sortedData = [...data].sort((a, b) => {
      const valueA = a[sortingInd];
      const valueB = b[sortingInd];

      if (valueA === undefined || valueB === undefined) return 0;

      let comparison = 0;

      if (typeof valueA === "string" && typeof valueB === "string") {
        comparison = valueA.localeCompare(valueB);
      } else if (typeof valueA === "number" && typeof valueB === "number") {
        comparison = valueA - valueB;
      }

      return sortOption === "asc" ? comparison : -comparison;
    });
    return sortedData;
  };

  const applyFilters = (data) => {
    // console.log(data);
    return data.filter((row) => {
      // Extract relevant fields from the row
      const category = row[2]; // Category is at index 2
      // console.log("status: ", row[3]);
      const completeSVG = `<svg xmlns="http://www.w3.org/2000/svg" width="38" viewBox="0 0 100 100">
  <circle cx="50" cy="50" r="45" fill="none" stroke="green" stroke-width="10"/>
  <path d="M30 50 L45 65 L70 35" fill="none" stroke="green" stroke-width="10" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;
      const inProgressSVG = `<svg width="38" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.9998 0.0299988V0.0499988V2.05C15.3898 2.59 18.4998 6.58 17.9598 10.97C17.4998 14.61 14.6398 17.5 10.9998 17.93V19.93C16.4998 19.38 20.4998 14.5 19.9498 9C19.4998 4.25 15.7298 0.499999 10.9998 0.0299988ZM8.9998 0.0599988C7.0498 0.249999 5.18981 0.999999 3.6698 2.26L5.0998 3.74C6.2198 2.84 7.5698 2.26 8.9998 2.06V0.0599988ZM2.2598 3.67C0.999805 5.19 0.249805 7.04 0.0498047 9H2.0498C2.2398 7.58 2.7998 6.23 3.6898 5.1L2.2598 3.67ZM13.4998 6.5L8.6198 11.38L6.4998 9.26L5.43981 10.32L8.6198 13.5L14.5598 7.56L13.4998 6.5ZM0.0598047 11C0.259805 12.96 1.0298 14.81 2.2698 16.33L3.6898 14.9C2.8098 13.77 2.2398 12.42 2.0598 11H0.0598047ZM5.0998 16.37L3.6698 17.74C5.1798 19 7.0398 19.79 8.9998 20V18C7.5798 17.82 6.2298 17.25 5.0998 16.37Z" fill="#1232C3"/>
</svg>`;
      const normalizeSVG = (svg) => {
        if (typeof svg === "string") {
          return svg.replace(/\s+/g, "").trim();
        }
        console.error("Expected a string, but received:", typeof svg);
        return ""; // Or handle appropriately
      };

      const status = isTemplateSection
        ? row[3]
        : normalizeSVG(row[3]) === normalizeSVG(completeSVG)
          ? "Complete"
          : normalizeSVG(row[3]) === normalizeSVG(inProgressSVG)
            ? "In progress"
            : "Something went wrong"; // Status is at index 3
      const lastUpdatedOn = row[6]; // Last updated date is at index 6

      // Convert the lastUpdatedOn string to a Date object
      const lastUpdatedOnDate = lastUpdatedOn ? new Date(lastUpdatedOn) : null;

      // Apply filters
      const matchesCategory =
        filterCriteria.categories.length === 0 ||
        filterCriteria.categories.includes(category);

      const matchesStatus =
        filterCriteria.status.length === 0 ||
        filterCriteria.status.includes(status);
      // console.log(status);
      // console.log(filterCriteria);

      const matchesLastUpdatedOn =
        !filterCriteria.lastUpdatedOn ||
        (lastUpdatedOnDate &&
          lastUpdatedOnDate >= filterCriteria.lastUpdatedOn);

      return matchesCategory && matchesStatus && matchesLastUpdatedOn;
    });
  };

  useEffect(() => {
    if (!extractedData) return;
    const getFilteredAndSortedData = () => {
      let dataToFilter = extractedData;

      if (searchText) {
        dataToFilter = dataToFilter.filter((row) =>
          row[1]?.toLowerCase().includes(searchText.toLowerCase()),
        );
      }

      dataToFilter = applyFilters(dataToFilter);
      return sortData(dataToFilter);
    };

    setFilteredExtractedData(getFilteredAndSortedData());
  }, [searchText, extractedData, filterCriteria]);

  useEffect(() => {
    if (!extractedData) return;
    const getFilteredAndSortedData = () => {
      const dataToFilter = searchText
        ? extractedData.filter((row) =>
            row[1]?.toLowerCase().includes(searchText.toLowerCase()),
          )
        : extractedData;
      return sortData(dataToFilter);
    };

    setFilteredExtractedData(getFilteredAndSortedData());
  }, [searchText, extractedData]);

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      tableTitle: showTitle ? prevState?.tableTitle : fileInfo?.fileName || "",
      tableData: extractedData || [],
      headerData: headerData || [],
    }));
  }, [extractedData, headerData, showTitle, fileInfo]);

  const [dropdownState, setDropdownState] = useState({
    showOptions: { rowIndex: null, cellIndex: null },
    position: { top: 0, left: 0 },
  });

  const handleDropdownClick = useCallback((rowIndex, cellIndex, event) => {
    event.preventDefault();
    event.stopPropagation();

    // Get the click target's position relative to its offset parent
    const rect = event.currentTarget.getBoundingClientRect();
    const parentRect = event.currentTarget.offsetParent.getBoundingClientRect();

    setDropdownState((prev) => ({
      showOptions: {
        rowIndex:
          prev.showOptions.rowIndex === rowIndex &&
          prev.showOptions.cellIndex === cellIndex
            ? null
            : rowIndex,
        cellIndex:
          prev.showOptions.rowIndex === rowIndex &&
          prev.showOptions.cellIndex === cellIndex
            ? null
            : cellIndex,
      },
      position: {
        top: rect.top, // Position below the clicked element
        left: rect.left,
      },
    }));
  }, []);

  const handleClose = () => {
    setDropdownState({
      showOptions: { rowIndex: null, cellIndex: null },
      position: { top: 0, left: 0 },
    });
  };

  const handleRowEdit = useCallback(
    (rowIndex) => {
      if (isTemplateSection || isHomeSection) {
        setSelectedTemplateId(filteredExtractedData[rowIndex][0]);
        return;
      }
      setState((prev) => ({
        ...prev,
        editingRows: {
          ...prev.editingRows,
          [rowIndex]: !prev.editingRows[rowIndex],
        },
      }));
    },
    [
      isTemplateSection,
      setSelectedTemplateId,
      isHomeSection,
      filteredExtractedData,
    ],
  );

  const handleRemoveRow = useCallback(
    (id) => {
      // Show confirmation modal instead of deleting immediately
      setDeleteConfirmationState({
        showModal: true,
        idToDelete: id
      });
      
      // Keep dropdown open until user makes a decision
    },
    [onDelete],
  );

  const confirmDelete = () => {
    // Actually perform the deletion
    onDelete(deleteConfirmationState.idToDelete);
    
    // Close the dropdown and the confirmation modal
    setDropdownState({
      showOptions: { rowIndex: null, cellIndex: null },
      position: { top: 0, left: 0 },
    });
    
    setDeleteConfirmationState({
      showModal: false,
      idToDelete: null
    });
  };

  const cancelDelete = () => {
    // Just close the confirmation modal without deleting
    setDeleteConfirmationState({
      showModal: false,
      idToDelete: null
    });
  };

  const handleViewAndManageTemplate = useCallback(() => {
    setDropdownState((prev) => ({
      ...prev,
      showOptions: { rowIndex: null, cellIndex: null },
    }));
  }, [setDropdownState]);

  useEffect(() => {
    const sortedData = sortData(filteredExtractedData);
    setFilteredExtractedData(sortedData);
  }, [sortColumn, sortOption]);

  const handleFinish = async (type) => {
    if (type === 2) {
      // for create data table option
      setShowFileInfo(true);
      return;
    }
    // Assume data.mandatoryFields and templateClass are available in scope
    const allMandatoryFieldsValid = data.mandatoryFields.every(
      (field) => data.templateClass[field] === 1,
    );

    if (!allMandatoryFieldsValid) {
      // for giving mandatory fields missing warning
      setShowMandatoryWarning(true);
      return;
    }

    if (type === 1) {
      // for finish mapping option in mapping screen
      if (JSON.stringify(defaultMap) !== JSON.stringify(data.fieldMapping)) {
        // for showing save mapping popup
        setShowSaveMapping(true);
        return;
      }
      const values = Object.values(data.dataFileClass);
      const hasNegativeOne = values.slice(2).some((val) => val === -1);
      if (hasNegativeOne) {
        // for giving optional fields missing warning
        setShowOptionalWarning(true);
        return;
      }
      setShowFinalize(true);
      return;
    } else if (type === 3) {
      setShowFinalize(true);
      setShowOptionalWarning(false);
      return;
    }
    setShowOptionalWarning(false);

    // Use the appropriate loading state based on showLoader flag
    loadingState.setIsLoading(true);

    try {
      const res = await api.post("/save", {
        fileId,
        userID,
        route: "/data-management/dataVerify",
      });
      navigate.push(`/data-management/dataVerify?fileId=${fileId}&userID=${userID}`);
    } catch (error) {
      setErrMessage(error.message);
      console.error("Error saving file:", error);
    }

    // Reset loading state with appropriate function
    loadingState.setIsLoading(false);
  };

  const handleSave = async () => {
    setIsSaveClick(true);
    if (JSON.stringify(defaultMap) !== JSON.stringify(data.fieldMapping)) {
      setShowSaveMapping(true);
      return;
    }

    // Use the appropriate loading state based on showLoader flag
    loadingState.setIsLoading(true);

    try {
      const res = await api.post("/save", {
        fileId,
        userID,
        route: location.pathname,
      });
      setShowSaved(true);
      setSaveMessage(res.data.message);
    } catch (error) {
      setErrMessage(error.message);
      console.error("Error saving file:", error);
    }

    // Reset loading state with appropriate function
    loadingState.setIsLoading(false);
  };

  const handleSaveMappingKey = async () => {
    // Use the appropriate loading state based on showLoader flag
    loadingState.setIsLoading(true);

    try {
      const res = await api.post("/saveMapping", {
        name,
        mapping: data.fieldMapping,
        fileId,
      });
      setName("");
      setFileInfo((prev) => ({ ...prev, mappingKeyId: res.data.id }));
      const resp = await api.post("/save", {
        fileId,
        userID,
        route: location.pathname,
      });
      setShowSaveMapping(false);
      setShowSaved(true);
      setSaveMessage(resp.data.message);
      setDefaultMap(JSON.parse(JSON.stringify(data.fieldMapping)));
    } catch (err) {
      setErrMessage(err?.response?.data?.message || err);
      console.error("Error saving file:", err);
    }

    // Reset loading state with appropriate function
    loadingState.setIsLoading(false);
  };

  useEffect(() => {
    if (showSaved) {
      const timer = setTimeout(() => {
        setShowSaved(false);
        setSaveMessage(null);
      }, 3000);
      return () => {
        if (isMappingScreen) {
          if (isSavedClick) {
            setIsSaveClick(false);
          } else {
            const values = Object.values(data.dataFileClass);
            const hasNegativeOne = values.slice(2).some((val) => val === -1);
            if (hasNegativeOne) {
              // for giving optional fields missing warning
              setShowOptionalWarning(true);
              return;
            }
            setShowFinalize(true);
          }
        } else {
          setShowUploadEvidencePopUp(true);
        }
        clearTimeout(timer);
      };
    }
  }, [showSaved]);

  const handleOverWrite = async () => {
    // Use the appropriate loading state based on showLoader flag
    loadingState.setIsLoading(true);

    try {
      const mappingKeysetId = data.mappingKeySet.id; // Assuming this is from your data object
      // Send the data via POST request
      const resp = await api.post("/saveMapping", {
        name,
        mapping: data.fieldMapping,
        id: mappingKeysetId,
        fileId,
        overwrite: true,
      });
      setName("");
      setShowSaved(true);
      setShowSaveMapping(false);
      setSaveMessage(resp.data.message);
      setDefaultMap(JSON.parse(JSON.stringify(data.fieldMapping)));
    } catch (err) {
      setErrMessage(err.response.data.message);
      console.error("Error in posting data:", err);
    }

    // Reset loading state with appropriate function
    loadingState.setIsLoading(false);
  };

  const handleCreateTable = async () => {
    // console.log(showLoader);
    // Use the appropriate loading state based on showLoader flag
    loadingState.setIsLoading(true);

    console.log("Loading started");
    try {
      // Send the data via POST request
      const resp = await api.post("/createTable", {
        name,
        userID,
        mapping: data?.fieldMapping,
        fileId,
      });

      console.log("Loading finished, setting showSaved to true");

      setShowSaved(true);
      setShowFileInfo(false);
      setSaveMessage(resp.data.message);

      // Verify state was updated
      console.log("States updated, showSaved should be true now");
    } catch (err) {
      // Reset loading state with appropriate function

      setErrMessage(err?.response?.data?.message);
      console.error("Error in posting data:", err);
    } finally {
      loadingState.setIsLoading(false);
    }
  };

  const onCancelUnsaved = () => {
    setShowUnsavedWarning(false);
    setOption("");
  };

  const onConfirmUnsaved = () => {
    setShowUnsavedWarning(false);
    if (option === "go back") {
      navigate.goBack();
    } else {
      navigate.push(`/data-management?userID=${userID}`);
    }
    setOption("");
  };

  // Use the appropriate isLoading check based on showLoader flag
  if (showLoader && loadingState.isLoading) {
    return <Loader />;
  }

  return (
    <div className={styles.container}>
      {showBackButton ? (
        <button
          className={styles.backButton}
          onClick={() => {
            if(isDataFileUploadSection){
              setShowUnsavedWarning(true);
              setOption("go back");
            }
            else{
              navigate.goBack();
            }
          }}
        >
          <svg
            width="8"
            height="12"
            viewBox="0 0 8 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 1.42L3.42 6L8 10.59L6.59 12L0.59 6L6.59 1.23266e-07L8 1.42Z"
              fill="#1232C3"
            />
          </svg>
          Back
        </button>
      ) : null}

      {showSaved ? <UploadSuccess message={`${saveMessage}`} /> : null}

      {currentStep ? <ProgressBar currentStep={currentStep} /> : null}

      {showSearchFilter && (
        <SearchFilter
          categories={categories}
          setSearchText={setSearchText}
          setFilterCriteria={setFilterCriteria}
          statusOptions={
            isTemplateSection
              ? ["Active", "Inactive"]
              : ["In progress", "Complete", "Something went wrong"]
          }
        />
      )}

      {showUnsavedWarning ? (
        <WarningModal
          icon={
            <i
              className="bi bi-exclamation-triangle-fill"
              style={{ color: "#D6746F" }}
            ></i>
          }
          message={`Are you sure you want to ${option} before completion?`}
          okButton="Yes"
          cancelButton="Cancel"
          onCancel={onCancelUnsaved}
          onSave={onConfirmUnsaved}
        />
      ) : null}

      {showTitle ? (
        <div className={styles.titleWrapper}>
          <div className={styles.fileInfo}>
            <p>
              {extraInfo?.fileName} uploaded
              <br />
              <b>{fileInfo?.fileSize} KB</b>
            </p>
            <p>
              Previewing {fileInfo?.previewRows?.length} of{" "}
              {fileInfo?.totalRows} rows
            </p>
          </div>
        </div>
      ) : null}

      {isMappingScreen ? (
        <div className={styles.extraInfo}>
          <b>
            <p>{extraInfo?.title}</p>
          </b>
          <div className={styles.extraInfoTitleBar}>
            <b>
              <p>{extraInfo?.typeName}</p>
            </b>
            <p>{extraInfo?.fileName}</p>
          </div>
        </div>
      ) : null}

      {isTemplateSection ? (
        !filteredExtractedData?.length ? (
          searchText ? (
            <div className={styles.noTemplateWarn}>
              <i className="bi bi-exclamation-circle"></i>
              <p>No template exists with given name</p>
            </div>
          ) : (
            <div className={styles.noTemplateWarn}>
              <i className="bi bi-exclamation-circle"></i>
              <p>
                No template exists. Please upload the template you have created
                for a data category
              </p>
            </div>
          )
        ) : null
      ) : null}

      {showSaveMapping ? (
        <SaveMapping
          name={name}
          setName={setName}
          title="Mapping Key Name"
          errMessage={errMessage}
          showOverWrite={data.mappingKeySet?.id !== undefined}
          onOverWrite={handleOverWrite}
          onCancel={() => setShowSaveMapping(false)}
          onSave={handleSaveMappingKey}
        />
      ) : null}

      {showFileInfo ? (
        <SaveMapping
          name={name}
          setName={setName}
          title="Table Name"
          errMessage={errMessage}
          onCancel={() => setShowFileInfo(false)}
          onSave={handleCreateTable}
        />
      ) : null}

      {deleteConfirmationState.showModal && (
        <WarningModal
          icon={
            <i
              className="bi bi-exclamation-triangle-fill"
              style={{ color: "#D6746F" }}
            ></i>
          }
          message="Are you sure you want to delete?"
          okButton="Delete"
          cancelButton="Cancel"
          onCancel={cancelDelete}
          onSave={confirmDelete}
        />
      )}

      {showMandatoryWarning ? (
        <WarningModal
          icon={
            <i
              className="bi bi-exclamation-triangle-fill"
              style={{ color: "#D6746F" }}
            ></i>
          }
          message="Mandatory fields required for template are missing in source file. Please verify mapping and upload correct file."
          okButton="OK"
          onSave={() => setShowMandatoryWarning(false)}
        />
      ) : null}

      {showUploadEvidencePopUp ? (
        <WarningModal
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="#1232C3"
              className="bi bi-info-circle"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
            </svg>
          }
          message="Proceed to upload Evidence"
          cancelButton="Upload Later"
          okButton="Upload"
          onCancel={() => navigate.push(`/data-management?userID=${userID}`)}
          onSave={() =>
            navigate.push(`/data-management/listEvidence?userID=${userID}&fileId=${fileId}`)
          }
        />
      ) : null}

      {showFinalize ? (
        <WarningModal
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="#1232C3"
              className="bi bi-info-circle"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
            </svg>
          }
          message="This action will finalise the data to template mapping."
          okButton="Continue"
          cancelButton="Cancel"
          onSave={() => handleFinish(4)}
          onCancel={() => setShowFinalize(false)}
        />
      ) : null}

      {showOptionalWarning ? (
        <WarningModal
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="#D6746F"
              className="bi bi-info-circle"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
            </svg>
          }
          message="Unmapped data fields present. The data from these fields will not be uploaded. Confirm to proceed."
          okButton="Continue"
          cancelButton="Cancel"
          onCancel={() => setShowOptionalWarning(false)}
          onSave={() => handleFinish(3)}
        />
      ) : null}

      <div className={styles.tableResponsive}>
        <table className={styles.table}>
          <thead>
            <tr>
              {state?.headerData?.map((header, index) => (
                <TableHeader
                  key={index}
                  header={header}
                  units={fileUnits}
                  index={index}
                  showUnits={showUnits}
                  selectedOptions={state.selectedOptions}
                  showMatching={showMatching}
                  showSorting={showSorting}
                  isTemplateSection={isTemplateSection}
                  unmatchedFileColumns={unmatchedFileColumns}
                  templateHeaders={data?.templateHeaders?.slice(1)}
                  sortColumn={sortColumn}
                  setSortColumn={setSortColumn}
                  sortOption={sortOption}
                  setSortOption={setSortOption}
                  setFileInfo={setFileInfo}
                  handleAddClass={() => handleAddClass(header)}
                />
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredExtractedData?.map((row, rowIndex) => (
              <tr key={rowIndex + 1} className={styles.tableRow}>
                <TableRow
                  showCheckBox={showCheckBox}
                  row={row}
                  fileInfo={fileInfo}
                  sectionType={sectionType}
                  rowIndex={rowIndex}
                  dropdownState={dropdownState}
                  handleDropdownClick={handleDropdownClick}
                  handleViewAndManageTemplate={handleViewAndManageTemplate}
                  handleRemoveRow={handleRemoveRow}
                  handleRowEdit={handleRowEdit}
                  handleClose={handleClose}
                  shouldSlice={shouldSlice}
                />
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {showMappingTip ? (
        <p className={styles.mappingTip}>
          <b>Tip :</b> Verify & map the ingested data table header with the
          template header.
        </p>
      ) : null}

      {showSaveCancelButton ? (
        <SaveCancelButton
          message={message}
          handleSave={handleSave}
          handleFinish={() =>
            isMappingScreen ? handleFinish(1) : handleFinish(2)
          }
          isMappingScreen={isMappingScreen}
          handleCancel={() => {
            setShowUnsavedWarning(true);
            setOption("cancel");
          }}
        />
      ) : null}
    </div>
  );
};

export default ShowDataTable;
