import React from "react";
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import styles from "../ShowDataTable.module.css";

const TableHeader = ({
  header,
  units,
  index,
  showUnits,
  showSorting,
  showMatching,
  sortColumn,
  setSortColumn,
  handleAddClass,
  setFileInfo = null,
  sortOption,
  setSortOption,
  templateHeaders,
  isTemplateSection,
}) => {
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState(""); // Search term for filtering
  const [filteredOptions, setFilteredOptions] = useState([]); // Filtered dropdown options

  // Initialize filtered options for the dropdown
  useEffect(() => {
    if (templateHeaders?.includes(header)) {
      setFilteredOptions(templateHeaders);
    } else {
      setFilteredOptions([]); // No options if the header isn't in unmatchedFileColumns
    }
  }, [header, templateHeaders]);

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);
    setFilteredOptions(
      templateHeaders.filter(
        (option) =>
          option.toLowerCase()?.includes(value) &&
          templateHeaders?.includes(header),
      ),
    );
  };

  const handleDropdown = () => {
    setOpen(!open);
    setSearchTerm("");
    setFilteredOptions(templateHeaders);
  };

  // console.log(sortColumn, sortOption);

  const handleFieldSelection = (selectedField) => {
    setFileInfo((prevFileInfo) => {
      const updatedFileInfo = { ...prevFileInfo };

      // Find if the selected field is already mapped to another header
      const currentMappedHeader = Object.keys(
        updatedFileInfo.fieldMapping,
      ).find((key) => updatedFileInfo.fieldMapping[key] === selectedField);

      // If already mapped, reset that header's mapping
      if (currentMappedHeader) {
        updatedFileInfo.fieldMapping[currentMappedHeader] = null;
        updatedFileInfo.templateClass[selectedField] = -1;
        updatedFileInfo.dataFileClass[currentMappedHeader] = -1;
      }

      // Find the previously mapped field for the current header
      const previousMapping = updatedFileInfo.fieldMapping[header];

      // If there was a previous mapping, handle it
      if (previousMapping) {
        updatedFileInfo.templateClass[previousMapping] = -1;
      }

      // Update field mapping
      updatedFileInfo.fieldMapping[header] = selectedField;

      // Update class values
      updatedFileInfo.templateClass[selectedField] = 1;
      updatedFileInfo.dataFileClass[header] = 1;

      return updatedFileInfo;
    });

    // Close dropdown
    setOpen(false);
  };

  return (
    <th key={index} className={styles.tableHeaderCell}>
      <div className={`${handleAddClass(header)} ${styles.header}`}>
        <>
          {showUnits ? (
            <>
              <span>
                <p className={styles.headerText}>{header}</p>
                {header !== "#" ? (
                  <p
                    className={styles.headerText}
                    style={{ fontWeight: "400", color: "#181818" }}
                  >
                    {units[header]}
                  </p>
                ) : null}
              </span>
            </>
          ) : (
            <p className={styles.headerText}>{header}</p>
          )}

          {!(
            header === "#" ||
            header === "Actions" ||
            (header === "Status" && !isTemplateSection)
          ) ? (
            showSorting ? (
              <select
                onClick={() => {
                  if (sortColumn === index) {
                    if (sortOption === "asc") {
                      setSortOption("desc");
                    } else {
                      setSortOption("asc");
                    }
                  } else {
                    setSortOption("asc");
                    setSortColumn(index);
                  }
                }}
                className={`${handleAddClass(index)} ${styles.select} ${
                  showSorting & (sortColumn === index)
                    ? sortOption === "asc"
                      ? styles.sortAsc
                      : styles.sortDsc
                    : styles.normal
                }`}
              ></select>
            ) : showMatching ? ( // Show dropdown only for matching headers
              <div className={styles.dropdown}>
                <div
                  className={`${styles.match}`}
                  onClick={handleDropdown}
                ></div>
                <ul
                  className={`${styles.dropdownList} ${
                    open ? "" : styles.hidden
                  }`}
                >
                  <input
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={handleSearch}
                    className={styles.searchInput}
                  />
                  <button
                    className={styles.closeButton}
                    onClick={handleDropdown}
                  >
                    &times;
                  </button>
                  {filteredOptions?.map((option, optionIndex) => (
                    <li
                      key={optionIndex}
                      className={styles.dropdownItem}
                      onClick={() => handleFieldSelection(option)}
                    >
                      {option}
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <select
                value={1}
                style={{ opacity: "0" }}
                className={`${handleAddClass(index)} ${styles.select} ${
                  showSorting
                    ? sortOption === "asc"
                      ? styles.sortAsc
                      : styles.sortDsc
                    : styles.match
                }`}
              ></select>
            )
          ) : null}
        </>
      </div>
    </th>
  );
};

export default TableHeader;
