import React, { useEffect, useState } from 'react'
import DashboardHeader from "../includes/dashboardHeader/DashboardHeader.js";
import DashboardSidebar from "../includes/dashboardSidebar/DashboardSidebar.js";
import FooterComponent from "../includes/dashboardFooter/FooterComponent";
import {
  Route,
  Switch,
  Link,
  useLocation,
  useHistory,
} from "react-router-dom";
import ShowDataTable from "./ShowDataTable/ShowDataTable";
import CreateRequest from "./APIScreen/CreateRequest";
import TemplateComponent from "./Template/TemplatePage";
import TemplateView from "./Template/TemplateView/TemplateView";

import styles from "./DataManagement.module.css";
import FileUpload from "./FileUpload/FileUpload";
import UploadEvidence from "./UploadEvidence/UploadEvidence";
import ListEvidence from "./ListEvidence/ListEvidence";
import ApiSection from "./APISection/ApiSection";
import api from "./Api";
import Analytics from "./Analytics/Analytics";
import Loader from "./Loader/Loader.js";
import WarningModal from "./WarningModal/WarningModal";


const applyClass = {
  ".": 0,
  "#": 0,
  Title: 0,
  Category: 0,
  Status: 0,
  Actions: 0,
  "Last updated by": 0,
  "Last updated on": 0,
};

const DataManagement = () => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useState(new URLSearchParams(location.search));
  const [select, setSelect] = useState(() => parseInt(searchParams.get("section") || 1));
  const [fileInfo, setFileInfo] = useState(null);
  const [defaultMap, setDefaultMap] = useState(null);
  const fileId = parseInt(searchParams.get("fileId") || null);
  const [categories, setCategories] = useState(null);
  const [filteredPreviewRows, setFilteredPreviewRows] = useState(null);
  const navigate = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [showError, setShowError] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [userID, setUserID] = useState(localStorage.getItem("UserId"));
  useEffect(() => {
    // Ensure userID is set from localStorage when component mounts
    if (!userID) {
      setUserID(localStorage.getItem("UserId"));
    }
    else{
      setIsLoading(false);
    }
  }, [localStorage.getItem("UserId")]);

  const TABS = [
    { id: 1, path: `/data-management?userID=${userID}`, label: "Upload Files" },
    { id: 3, path: `/data-management/apis?section=3&userID=${userID}`, label: "API Integration" },
    { id: 4, path: `/data-management/analytics?section=4&userID=${userID}`, label: "Analytics" },
    { id: 2, path: `/data-management/templates?section=2&userID=${userID}`, label: "Templates" },
  ];

  // console.log(localStorage.getItem("UserId"));
  // console.log(localStorage.getItem("UserRole"));
  // console.log(localStorage.getItem("UserType"));
  // console.log(CacheStorage.getItem("userToken"));

  const handleClick = (opt) => {
    setSelect(opt);
    setSearchParams(new URLSearchParams(location.search));
  };

  const handleFetchCategories = async () => {
    if (!categories) {
      await api.get("/getAllCategories").then((res) => {
        const data = res.data;
        setCategories(data);
      });
    }
  };

  useEffect(() => {
    if (fileInfo?.fileId !== fileId) {
      setFileInfo(null);
      setFilteredPreviewRows(null);
    }
    const queryParams = new URLSearchParams(location.search);
    const route = location.pathname;

    const fetchFileInfo = async () => {
      try {
        setIsLoading(true);
        const res = await api.post("/getFileInfo", {
          fileId: parseInt(queryParams.get("fileId")),
          route,
          fetchAll: route === "/data-management/viewData" ? true : false,
        });
        setFileInfo(res.data);
        setDefaultMap(
          JSON.parse(JSON.stringify(res.data.fieldMapping || undefined)),
        );
      } catch (err) {
        console.error("Error fetching file info:", err);
      }
      setIsLoading(false);
    };
    console.log(queryParams.get("fileId"));
    if (queryParams.get("fileId")) {
      setFileInfo(null);
      if (["/data-management/dataVerify", "/data-management/fileUploadWithTemplate", "/data-management/viewData"].includes(route)) {
        fetchFileInfo();
      }
    }
    // console.log("heyyeeefaf");

    // console.log(route);
  }, [location.pathname]);



  const handleFileUpload = async (data) => {
    const {
      selectedFile,
      categorySelected,
      templateSelected,
      mappingKeySelected,
    } = data;

    if (!selectedFile) {
      alert("Please select a file to upload!");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("userId", userID);
    formData.append("category", JSON.stringify(categorySelected) || "");
    formData.append("template", JSON.stringify(templateSelected) || "");
    formData.append("mappingKey", JSON.stringify(mappingKeySelected) || "");
    setIsLoading(true);
    try {
      const res = await api.post("/uploadDataFile", formData);
      setFileInfo(res.data);
      if (
        categorySelected?.name === "Unknown Data Category" ||
        templateSelected?.name === "No template available"
      ) {
        navigate.push(`/data-management/dataVerify?userID=${userID}&fileId=${res.data.fileId}`);
      } else {
        setDefaultMap(JSON.parse(JSON.stringify(res.data.fieldMapping)));
        navigate.push(
          `/data-management/fileUploadWithTemplate?userID=${userID}&fileId=${res.data.fileId}`,
        );
      }
    } catch (err) {
      console.log(err);
      setShowError(true);
      setErrMessage(err.response.data.message);
      console.log("An error occurred during file upload. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (fileInfo) {
      const mappedIndicesToRemove = Object.entries(fileInfo?.fieldMapping || {})
        .filter(([_, value]) => value === null) // Get only null-mapped fields
        .map(([key]) => fileInfo.fileHeaders.indexOf(key)) // Find their indices in fileHeaders
        .filter((index) => index !== -1); // Ignore -1 (not found cases)

      // Filter out the unwanted columns from previewRows
      setFilteredPreviewRows(
        fileInfo?.previewRows?.map((row) =>
          !fileInfo?.fieldMapping ||
            Object.values(fileInfo.fieldMapping).every((value) => value === null)
            ? row
            : row.filter(
              (_, colIndex) => !mappedIndicesToRemove.includes(colIndex + 1),
            ),
        ),
      );
    }
  }, [fileInfo]);

  useEffect(() => {
    handleFetchCategories();
  }, []);

  // useEffect(() => {
  //   console.log(fileInfo);
  // }, [fileInfo]);

  // Filter ApplyClass to exclude removed columns
  const filteredApplyClass =
    !fileInfo?.fieldMapping ||
      Object.values(fileInfo.fieldMapping).every((value) => value === null)
      ? fileInfo?.dataFileClass
        ? Object.fromEntries(
          Object.keys(fileInfo.dataFileClass).map((key) => [key, 2]),
        )
        : {}
      : Object.fromEntries([
        ["#", 2],
        ...Object.entries(fileInfo.fieldMapping)
          .filter(([_, key]) => key !== null) // Remove null mappings
          .map(([_, key]) => [key, 2]),
      ]);


  return [
    <DashboardHeader key="header"></DashboardHeader>,
    <div id="wrapper" key="body-wrapper">
      <DashboardSidebar></DashboardSidebar>
      <div className={styles["project-data"]}>
        <h2>Project Data</h2>

        <div className={styles.tabs}>
          {TABS.map(tab => (
            <Link key={tab.id} to={tab.path} className={styles.tab}>
              <button
                className={select === tab.id ? styles.active : undefined}
                onClick={() => {
                  setFileInfo(null);
                  handleClick(tab.id);
                }}
              >
                {tab.label}
              </button>
            </Link>
          ))}
        </div>

        <div className={styles["upload-section"]}>
          {showError ? (
            <WarningModal
              icon={
                <i
                  className="bi bi-exclamation-triangle-fill"
                  style={{ color: "#D6746F" }}
                ></i>
              }
              okButton="Ok"
              message={errMessage}
              onSave={() => {
                setShowError(false);
                setErrMessage("");
              }}
            />
          ) : null}

          {isLoading ? <Loader /> : <Switch>
            <Route
              exact
              path="/data-management"
              render={() => (
                <FileUpload
                  categories={categories}
                  handleFileUpload={handleFileUpload}
                  applyClass={applyClass}
                />
              )}
            />

            <Route
              exact
              path="/data-management/dataVerify"
              render={() => (
                <ShowDataTable
                  // Configuration flags
                  isDataFileUploadSection={true}
                  showBackButton={true}
                  showSaveCancelButton={true}
                  showUnits={true}
                  showSaveButton={true}
                  showSorting={true}
                  showMatching={false}
                  showTitle={true}
                  showSearchFilter={false}
                  showMappingTip={false}
                  showCheckBox={false}
                  shouldSlice={false}
                  // Step & state management
                  currentStep={2}
                  isFileUploaded={1}
                  isDataEditable={true}
                  isTemplateSection={false}
                  // Data
                  fileInfo={fileInfo}
                  data={fileInfo}
                  extraInfo={fileInfo?.fileDetail}
                  setFileInfo={setFileInfo}
                  // Styling
                  headerData={
                    !fileInfo?.fieldMapping ||
                      Object.values(fileInfo.fieldMapping).every(
                        (value) => value === null,
                      )
                      ? fileInfo?.fileHeaders
                      : ["#", ...Object.values(fileInfo?.fieldMapping || {}).filter(value => value !== null)]
                  }
                  fileUnits={
                    !fileInfo?.fieldMapping ||
                      Object.values(fileInfo.fieldMapping).every(
                        (value) => value === null,
                      )
                      ? fileInfo?.fileUnits // Use original units if no mapping
                      : Object.entries(fileInfo?.fieldMapping || {}).reduce(
                        (acc, [fileHeader, templateHeader]) => {
                          // Only include units for fields that have been mapped
                          if (
                            templateHeader !== null &&
                            fileInfo?.fileUnits[fileHeader]
                          ) {
                            acc[templateHeader] = fileInfo.fileUnits[fileHeader];
                          }
                          return acc;
                        },
                        {},
                      )
                  }
                  extractedData={filteredPreviewRows}
                  message="Create Data Table"
                  // Styling
                  ApplyClass={filteredApplyClass}
                />
              )}
            />

            <Route
              exact
              path="/data-management/viewData"
              render={() => (
                <ShowDataTable
                  // Configuration flags
                  showBackButton={true}
                  showSaveCancelButton={false}
                  showSaveButton={false}
                  showSorting={true}
                  showMatching={false}
                  showTitle={true}
                  showUnits={true}
                  showSearchFilter={false}
                  showMappingTip={false}
                  showCheckBox={false}
                  shouldSlice={false}
                  // Step & state management
                  isFileUploaded={1}
                  isDataEditable={true}
                  isTemplateSection={false}
                  // Data
                  fileInfo={fileInfo}
                  data={fileInfo}
                  extraInfo={fileInfo?.fileDetail}
                  setFileInfo={setFileInfo}
                  // Styling
                  headerData={
                    !fileInfo?.fieldMapping ||
                      Object.values(fileInfo.fieldMapping).every(
                        (value) => value === null,
                      )
                      ? fileInfo?.fileHeaders
                      : ["#", ...Object.values(fileInfo?.fieldMapping || {}).filter(value => value !== null)]
                  }
                  fileUnits={
                    !fileInfo?.fieldMapping ||
                      Object.values(fileInfo.fieldMapping).every(
                        (value) => value === null,
                      )
                      ? fileInfo?.fileUnits // Use original units if no mapping
                      : Object.entries(fileInfo?.fieldMapping || {}).reduce(
                        (acc, [fileHeader, templateHeader]) => {
                          // Only include units for fields that have been mapped
                          if (
                            templateHeader !== null &&
                            fileInfo?.fileUnits[fileHeader]
                          ) {
                            acc[templateHeader] = fileInfo.fileUnits[fileHeader];
                          }
                          return acc;
                        },
                        {},
                      )
                  }
                  extractedData={filteredPreviewRows}
                  message="Create Data Table"
                  // Styling
                  ApplyClass={filteredApplyClass}
                />
              )}
            />

            <Route
              exact
              path="/data-management/fileUploadWithTemplate"
              render={() => (
                <>
                  <ShowDataTable
                    // Configuration flags
                    showBackButton={false}
                    showSorting={false}
                    showMatching={true}
                    showTitle={true}
                    showSearchFilter={false}
                    showMappingTip={false}
                    showUnits={true}
                    shouldSlice={false}
                    // Step & state management
                    currentStep={1}
                    isFileUploaded={2}
                    isDataEditable={false}
                    isTemplateSection={false}
                    isMappingScreen={true}
                    // Data
                    data={fileInfo}
                    fileInfo={fileInfo}
                    extraInfo={fileInfo?.fileDetail}
                    headerData={fileInfo?.fileHeaders}
                    fileUnits={fileInfo?.fileUnits}
                    extractedData={fileInfo?.previewRows}
                    unmatchedTemplateFields={fileInfo?.unmatchedTemplateFields}
                    unmatchedFileColumns={fileInfo?.unmatchedFileColumns}
                    setFileInfo={setFileInfo}
                    // Styling
                    ApplyClass={fileInfo?.dataFileClass}
                  />
                  <hr className={styles.mapLine} />
                  <ShowDataTable
                    // Configuration flags
                    showSaveCancelButton={true}
                    showSaveButton={true}
                    setIsLoading={setIsLoading}
                    showLoader={false}
                    showSorting={false}
                    showMatching={false}
                    showTitle={false}
                    showSearchFilter={false}
                    showMappingTip={true}
                    // Step & state management
                    isFileUploaded={2}
                    isDataEditable={false}
                    isTemplateSection={false}
                    isMappingScreen={true}
                    // Data
                    data={fileInfo}
                    extraInfo={fileInfo?.templateInfo}
                    headerData={fileInfo?.templateHeaders}
                    defaultMap={defaultMap}
                    setDefaultMap={setDefaultMap}
                    setFileInfo={setFileInfo}
                    message="Finish Editing"
                    // Styling
                    ApplyClass={fileInfo?.templateClass}
                  />
                </>
              )}
            />

            <Route
              exact
              path="/data-management/templates"
              render={() => (
                <TemplateComponent categories={categories} ApplyClass={applyClass} />
              )}
            />

            <Route
              exact
              path="/data-management/apis"
              render={() => <ApiSection />}
            />

            <Route
              exact
              path="/data-management/createRequest"
              render={() => <CreateRequest />}
            />

            <Route
              exact
              path="/data-management/manageTemplate"
              render={() => <TemplateView categories={categories} />}
            />

            <Route
              exact
              path="/data-management/uploadEvidence"
              render={() => <UploadEvidence dropDownOptions={["city1", "city2", "city3"]} />}
            />

            <Route
              exact
              path="/data-management/listEvidence"
              render={() => (
                <ListEvidence currentStep={3} />
              )}
            />

            <Route
              exact
              path="/data-management/analytics"
              render={() => (
                <Analytics categories={categories} />
              )}
            />
          </Switch>}
        </div>
      </div>
    </div>,
    <FooterComponent key="footer"></FooterComponent>
  ];
};

export default DataManagement;
