import React from 'react';
import { useState, useEffect } from "react";
import styles from "./TemplateUploadForm.module.css";

const TemplateUploadForm = ({
  initialData,
  onCancel,
  onSave,
  categories,
  errMessage,
  showUploadForm,
  isLoading,
}) => {
  const [templateName, setTemplateName] = useState(""); // Form state
  const [dataCategory, setDataCategory] = useState(""); // Stores selected category ID
  const [status, setStatus] = useState("Active"); // Form state with default value
  const [isOpenStatus, setIsOpenStatus] = useState(false);
  const [isOpenCategory, setIsOpenCategory] = useState(false);
  const [dataCategoryName, setDataCategoryName] = useState("");

  // Sort categories alphabetically by name
  const sortedCategories = () => {
    if (!categories) return [];
    return [...categories].sort((a, b) => a.name.localeCompare(b.name));
  };

  // Populate form with the initial data when the component loads
  useEffect(() => {
    if (initialData) {
      setTemplateName(initialData?.name || ""); // Set the initial title
      setDataCategory(initialData?.category_id || ""); // Set the initial category ID (if available)
      setDataCategoryName(initialData?.categoryName);
      setStatus(initialData?.status === 1 ? "Active" : "Inactive"); // Set the initial status
    }
  }, [initialData]);

  const handleSave = () => {
    if (templateName && dataCategory) {
      onSave({
        title: templateName,
        category_id: dataCategory, // Send back the selected category ID
        status: status,
      });
    }
  };

  return (
    <div
      className={styles.formWrap}
      style={{ display: !showUploadForm ? "none" : "block" }}
    >
      <div className={styles.formContainer}>
        <div className={styles.name}>
          <label>Template Name</label>
          <input
            type="text"
            value={templateName}
            onChange={(e) => setTemplateName(e.target.value)}
            className={styles.inputField}
          />
          <p style={{ color: "#D6746F" }}>{errMessage}</p>
        </div>
        <div className={styles.statusType}>
          <div className={styles.dataCat}>
            <div className={styles.dropdown}>
              <label className={styles.label}>Data Category</label>
              <div
                className={`${styles.dropdownHeader}`}
                onClick={() => setIsOpenCategory(!isOpenCategory)} // Prevent opening if disabled
              >
                {dataCategoryName}
                <svg
                  style={{
                    transform: isOpenCategory
                      ? "rotate(90deg)"
                      : "rotate(270deg)",
                    transition: "transform 0.3s ease",
                  }}
                  width="8"
                  height="12"
                  viewBox="0 0 8 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 1.42L3.42 6L8 10.59L6.59 12L0.59 6L6.59 1.23266e-07L8 1.42Z"
                    fill="#1232C3"
                  />
                </svg>
              </div>
              <ul
                className={`${styles.dropdownList} ${isOpenCategory ? "" : styles.hidden}`}
              >
                {sortedCategories()?.map((category) => (
                  <li
                    key={category.category_id}
                    className={styles.dropdownItem}
                    onClick={() => {
                      setDataCategory(category.category_id);
                      setDataCategoryName(category.name);
                      setIsOpenCategory(!isOpenCategory);
                    }}
                  >
                    {category.name}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className={styles.status}>
            <div className={styles.dropdown}>
              <label className={styles.label}>Status</label>
              <div
                className={`${styles.dropdownHeader}`}
                onClick={() => setIsOpenStatus(!isOpenStatus)} // Prevent opening if disabled
                style={{ color: status === "Active" ? "#80BA78" : "#D6746F" }}
              >
                {status}
                <svg
                  style={{
                    transform: isOpenStatus
                      ? "rotate(90deg)"
                      : "rotate(270deg)",
                    transition: "transform 0.3s ease",
                  }}
                  width="8"
                  height="12"
                  viewBox="0 0 8 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 1.42L3.42 6L8 10.59L6.59 12L0.59 6L6.59 1.23266e-07L8 1.42Z"
                    fill="#1232C3"
                  />
                </svg>
              </div>
              <ul
                className={`${styles.dropdownList}  ${isOpenStatus ? "" : styles.hidden}`}
              >
                <li
                  className={styles.dropdownItem}
                  onClick={() => {
                    setStatus("Active");
                    setIsOpenStatus(!isOpenStatus);
                  }}
                >
                  Active
                </li>
                <li
                  className={styles.dropdownItem}
                  onClick={() => {
                    setStatus("Inactive");
                    setIsOpenStatus(!isOpenStatus);
                  }}
                >
                  Inactive
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className={styles.buttonGroup}>
          <button onClick={onCancel} className={styles.cancelButton}>
            Cancel
          </button>
          <button
            onClick={handleSave}
            disabled={!templateName || !dataCategory}
            className={styles.saveButton}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.58984 16.58L13.1698 12L8.58984 7.41L9.99984 6L15.9998 12L9.99984 18L8.58984 16.58Z"
                fill="#F7F7F8"
              />
            </svg>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default TemplateUploadForm;
